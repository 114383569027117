import React, {useContext, useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import List from '../../patterns/list/list';
import emptySearch from "../../assets/lists/empty_search.svg";
import {GridColumnVisibilityModel, GridEnrichedColDef, GridFilterListIcon, GridFilterModel, GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import {columnDefinition, valueGetter, ColumnType, actionColumn, gridAction, addSuccessAlert, addErrorAlert, enumFilterWithoutNull} from "../../patterns/list/listUtils";
import {RequestFilter, RequestFilterOperator, RequestOrder, manageStringError} from "../../common/methods/ApiService";
import {TAccessCode} from '../../interfaces/accessCode';
import {TOperation} from '../../interfaces/operation';
import {getExportOperationCodes, getOperationCodes, getAllOperationCodes, deleteCode, lockCode} from "../../services/AccessCodeService";
import {Box, Chip, Menu, Typography} from '@mui/material';
import {EuroPointToggle, PointsUnit} from '../../patterns/list/euroPointToggle';
import ListMenuItem from '../../patterns/list/listMenuItem';
import ListDeletedCodes from './listDeletedCodes';
import {getExportDocument} from "../../common/methods/exportDocument";
import DeleteDialog from '../../patterns/dialog/deleteDialog';
import {TAlert} from '../../patterns/list/listSnackbar';
import Panel, {PanelSize} from '../../patterns/panel/panel';
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from "@mui/icons-material/Delete";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InventoryIcon from "@mui/icons-material/Inventory";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AddIcon from "@mui/icons-material/Add";
import {PrimarySmallButton, SecondarySmallButton} from '../../components/buttons/mainButton';
import {IconPosition} from '../../components/buttons/buttonProps';
import CodeForm from './codeFormPanel';
import {QuickFilterType} from '../../interfaces/quickFilter';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {formatIds} from '../../common/methods/utils';
import {getCompany} from '../../services/CompanyService';
import {globalStoreReducer} from '../../common/methods/context-setter/globalStoreReducer';
import {UPDATE_ALL_WALLETS} from '../../common/methods/context-setter/globals';
import {StoreContext} from '../../common/struct/store';
import {AppContext} from '../../App';

export type ListOperationCodesProps = {
  operation: TOperation;
  addSuccess: (success: string) => void;
  addError: (error: string) => void;
}

export default function OperationCodes(props: ListOperationCodesProps): JSX.Element {
  const {operation, addSuccess, addError} = props;
  const {t} = useTranslation();  
  const apiRef = useGridApiRef();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [selectedUnit, setSelectedUnit] = useState(PointsUnit.EUROS);
  const [code, setCode] = useState<TAccessCode|null>(null);
  const [reload, setReload] = useState(false);
  const [openDeleteCode, setOpenDeleteCode] = useState(false);
  const [openLockCode, setOpenLockCode] = useState(false);
  const [openDeletedCodePanel, setOpenDeletedCodePanel] = useState<boolean>(false);
  const [openCodePanel, setOpenCodePanel] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number|null>(null);
  const [search, setSearch] = useState("");
  const [operationCodeCountWithFilters, setOperationCodeCountWithFilters] = useState<number>(0);
  const [quickFilters, setQuickFilters] = useState<GridFilterModel>({items: []});
  const [quickFilterSelected, setQuickFilterSelected] = useState<QuickFilterType|null>(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<undefined|HTMLElement>(undefined);
  const [lock, setLock] = useState<boolean>(true);

  const updateWallets = (): void => {
    getCompany(store.global.company.id)
      .then(company => {
        if (company) {
          globalStoreReducer(STORE, {type: UPDATE_ALL_WALLETS, wallets: company.wallets});
        }
      })
      .catch(error => manageStringError(error, addError, t))
  } 

  /**
   *   Quick filters 
   */

  const handleActionsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = (): void => {
    setActionsAnchorEl(undefined);
  };

  const updateFilters = (filterSelected: QuickFilterType, dateStart?: string, dateEnd?: string,): void => {
    if (filterSelected === quickFilterSelected) {      
      setQuickFilters({items: []});
      setQuickFilterSelected(null);
    } else {
      if (filterSelected == QuickFilterType.USED_CODE) {
        setQuickFilters({items: [{columnField: "quick", operatorValue: "=", value: "use"}]})
        setQuickFilterSelected(filterSelected);
      } else if (filterSelected == QuickFilterType.UNUSED_CODE) {
        setQuickFilters({items: [{columnField: "quick", operatorValue: "=", value: "unuse"}]})
        setQuickFilterSelected(filterSelected);
      } else {
        setQuickFilters({items: [{columnField: "createdAt", operatorValue: "after", value: dateStart}, {columnField: "createdAt", operatorValue: "before", value: dateEnd}]})
        setQuickFilterSelected(filterSelected);
      }
    }
  }

  const quickFilterAction = <Box>
    <PrimarySmallButton label={t("accessCodes.actions.quick_filter")} action={handleActionsClick}
      icon={GridFilterListIcon} position={IconPosition.LEFT} sx={{mr: 1}}/>
    <Menu id="actions-menu" open={actionsAnchorEl != null} onClose={handleActionsClose} anchorEl={actionsAnchorEl}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}} transformOrigin={{vertical: "top", horizontal: "right"}}
      sx={{"& .MuiPaper-root": {mt: 0, border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3}}}>
      <ListMenuItem label={t("accessCodes.actions.quick.actual_month")} icon={quickFilterSelected === QuickFilterType.ACTUALMONTH ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(QuickFilterType.ACTUALMONTH, moment().startOf('month').format('YYYY-MM-DD'), moment().endOf("month").format('YYYY-MM-DD')) }}/>
      <ListMenuItem label={t("accessCodes.actions.quick.use")} icon={quickFilterSelected === QuickFilterType.USED_CODE ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(QuickFilterType.USED_CODE, moment().startOf('month').format('YYYY-MM-DD'), moment().endOf("month").format('YYYY-MM-DD')) }}/>
      <ListMenuItem label={t("accessCodes.actions.quick.unuse")} icon={quickFilterSelected === QuickFilterType.UNUSED_CODE ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(QuickFilterType.UNUSED_CODE, moment().startOf('month').format('YYYY-MM-DD'), moment().endOf("month").format('YYYY-MM-DD')) }}/>
    </Menu>
  </Box>

  /**
   *   Hide default date column 
   */

  const visibilityModel: GridColumnVisibilityModel = {consumptionDate: false, lockDate: false};

  /**
   *   Export action
   */

  const exportParticipants = (): Promise<void> => {
    return getExportOperationCodes(operation.id!)
      .then(response => {
        getExportDocument(response)
      });
  }

  const exportAction = <ListMenuItem key={Math.random()} label={t("accessCodes.actions.export")} icon={FileDownloadIcon} action={(): any => exportParticipants()}/>

  /**
   *   Euro to point action
   */

  const euroToPointAction = <Box key={Math.random()} sx={{mr: 1}}><EuroPointToggle selected={selectedUnit} setSelected={setSelectedUnit}/></Box>

  /**
   *   Create code action
   */

  const openPanel = (): void => {
    setCode(null);
    setOpenCodePanel(true);
  }

  const createAction = <PrimarySmallButton key="create-code" label={t("accessCodes.actions.create")} icon={AddIcon} sx={{mr: 1}}
    position={IconPosition.LEFT} action={openPanel}/>

  const codePanelTitle = code ? t("accessCodes.actions.title_update") : t("accessCodes.actions.title_create");
  const codePanel = openCodePanel ? <Panel key="code-panel-template" title={codePanelTitle} size={PanelSize.SMALL} open={openCodePanel} close={(): void => setOpenCodePanel(false)}>
    <CodeForm operation={operation} addSuccess={addSuccess} addError={addError} setClose={(): void => setOpenCodePanel(false)} setReload={setReload} code={code}/>
  </Panel> : <></>

  /**
   *   Update code action
   */

  const updateCodeButton = (code: TAccessCode): void => {
    setCode(code);
    setOpenCodePanel(true);
  }

  /**
   *   Delete code action
   */

  const closeDeleteCode = (): void => setOpenDeleteCode(false)

  const deleteCodeButton = (code: TAccessCode): void => {
    setCode(code);
    setOpenDeleteCode(true);
  }

  const deleteCodeAction = async (): Promise<void> => {
    if (code == null && (selectedRows == 0 || selectedRows == null)) {
      return Promise.resolve();
    }

    let filters: RequestFilter[]|null = []; 
    let count = 1;    
    if (!selectAll && selectedRows != 0 && selectedRows != null) {
      filters = formatIds(Array.from(apiRef.current.getSelectedRows().keys()).map(key => key.toString()));  
      count = selectedRows > 1 ? selectedRows : 1;        
    } else if (!selectAll && (selectedRows == 0 || selectedRows == null) && code) {      
      filters = formatIds([code.id]);
    } else if (selectAll) {
      try {
        const res = await getAllOperationCodes(operation.id, search, null, [{field: "pagination", items: [{value: "false", operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]}]);
        const ids = res.map(item => item.id);
        filters = formatIds(ids);
        count = filters[0].items.length > 1 ? filters[0].items.length : 1; 
      } catch (error: any) {
        manageStringError(error, addError, t);
      }
    }    

    return deleteCode(operation.id, filters)
      .then(() => {
        setReload(true);
        updateWallets();
        addSuccessAlert(t("accessCodes.actions.delete_success", {count: count}), setAlerts);
      })
      .catch(error => { manageStringError(error, error => addErrorAlert(error, setAlerts), t), setReload(true) })
      .finally(closeDeleteCode)
  }

  const deleteCodes = <SecondarySmallButton key="delete-codes" label={t("accessCodes.actions.delete_codes")} icon={DeleteIcon} sx={{ml: 1}}
    position={IconPosition.LEFT} action={(): void => { setOpenDeleteCode(true) }}/>
  const deleteDialog = <DeleteDialog key="delete-code" open={openDeleteCode} close={closeDeleteCode}
    title={t("accessCodes.actions.delete_title", {count: (selectedRows != null ? selectedRows : 1)})} warning={t("accessCodes.actions.delete_warning", {count: (selectedRows != null ? selectedRows : 1)})} deleteElement={deleteCodeAction}/>

  /**
  *   Deleted code list action
  */

  const openDeletedPanelAction = <ListMenuItem label={t(`accessCodes.actions.archived`)} icon={InventoryIcon} action={(): void => setOpenDeletedCodePanel(true)}/>
  const deletedCodePanel = openDeletedCodePanel ? <Panel key="deleted-code-panel-template" title={t(`accessCodes.deleted.title`)} size={PanelSize.HUGE} open={openDeletedCodePanel} close={(): void => setOpenDeletedCodePanel(false)}>
    <ListDeletedCodes operation={operation} addSuccess={addSuccess} addError={addError} setReloadList={setReload} setClose={(): void => setOpenDeletedCodePanel(false)}/>
  </Panel> : <></>

  /**
  *   Lock code action
  */

  const closeLockCode = (): void => setOpenLockCode(false)

  const lockCodeButton = (code: TAccessCode|null, lock: boolean): void => {
    setCode(code);
    setLock(lock);
    setOpenLockCode(true);
  }

  const lockCodeAction = async (): Promise<void> => {
    if (code == null && (selectedRows == 0 || selectedRows == null)) {
      return Promise.resolve();
    }

    let filters: RequestFilter[]|null = []; 
    let count = 1;    
    if (!selectAll && selectedRows != 0 && selectedRows != null) {
      filters = formatIds(Array.from(apiRef.current.getSelectedRows().keys()).map(key => key.toString()));  
      count = selectedRows > 1 ? selectedRows : 1;        
    } else if (!selectAll && (selectedRows == 0 || selectedRows == null) && code) {      
      filters = formatIds([code.id]);
    } else if (selectAll) {
      try {
        const res = await getAllOperationCodes(operation.id, search, null, [{field: "pagination", items: [{value: "false", operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]}]);        
        const ids = res.map(item => item.id);
        filters = formatIds(ids);
        count = filters[0].items.length > 1 ? filters[0].items.length : 1; 
      } catch (error: any) {
        manageStringError(error, addError, t);
      }
    }

    filters.push({field: "lock", items: [{value: lock.toString(), operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]})

    return lockCode(operation.id, filters)
      .then(() => {
        setReload(true);
        addSuccessAlert(lock ? t("accessCodes.actions.lock_success", {count: count}) : t("accessCodes.actions.unlock_success", {count: count}), setAlerts);
      })
      .catch(error => manageStringError(error, error => addErrorAlert(error, setAlerts), t))
      .finally(closeDeleteCode)
  }
  
  const lockCodes = <SecondarySmallButton key="lock-codes" label={t("accessCodes.actions.lock_codes")} icon={NotInterestedIcon} sx={{ml: 1}}
    position={IconPosition.LEFT} action={(): void => { lockCodeButton(null, true) }}/>
  const unlockCodes = <SecondarySmallButton key="unlock-codes" label={t("accessCodes.actions.unlock_codes")} icon={CheckCircleOutlineIcon} sx={{ml: 1}}
    position={IconPosition.LEFT} action={(): void => { lockCodeButton(null, false) }}/>
  const lockDialog = <DeleteDialog key="lock-code" open={openLockCode} close={closeLockCode}
    title={lock ? t("accessCodes.actions.lock_title", {count: (selectedRows != null ? selectedRows : 1)}) : t("accessCodes.actions.unlock_title", {count: (selectedRows != null ? selectedRows : 1)})} warning={lock ? t("accessCodes.actions.lock_warning", {count: (selectedRows != null ? selectedRows : 1)}) : t("accessCodes.actions.unlock_warning", {count: (selectedRows != null ? selectedRows : 1)})} deleteElement={lockCodeAction}/>  

  /**
  *   List config
  */

  const loadCodes = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TAccessCode[]}> => {
    return getOperationCodes(operation.id, page, maxItems, search, order, filters).then((res) => {
      setOperationCodeCountWithFilters(res.totalItems);
      return res;
    });
  } 

  const operationAdminNameColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "accessCodes", "admin.name", 150, ColumnType.STRING, true, false, false),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.admin['name'], ColumnType.STRING)
    }
  }

  const amountColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "accessCodes", selectedUnit == PointsUnit.POINTS ? "reward.pointAmount" : "reward.amount", 140, selectedUnit == PointsUnit.POINTS ? ColumnType.POINTS : ColumnType.EUROS, true),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.reward ? (selectedUnit == PointsUnit.POINTS ? item.row.reward.pointAmount : item.row.reward.amount) : "0", selectedUnit == PointsUnit.POINTS ? ColumnType.POINTS : ColumnType.EUROS)
    }
  }

  const consumptionDateColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "accessCodes", "status", 300, ColumnType.DATE_TIME),
      type: "singleSelect",
      filterOperators: enumFilterWithoutNull,
      valueOptions: [{value: t(`accessCodes.columns.statusList.lock.value`), label: t(`accessCodes.columns.statusList.lock.label`)}, {value: t(`accessCodes.columns.statusList.use.value`), label: t(`accessCodes.columns.statusList.use.label`)}, {value: t(`accessCodes.columns.statusList.unuse.value`), label: t(`accessCodes.columns.statusList.unuse.label`)}, {value: t(`accessCodes.columns.statusList.expire.value`), label: t(`accessCodes.columns.statusList.expire.label`)}],
      renderCell: (item): JSX.Element => {
        return <Chip icon={item.row.lockDate ? <CloseIcon/> : (item.row.consumptionDate ? <CheckIcon/> : (new Date(item.row.expirationDate) < new Date() ? <ErrorIcon/> : <HourglassEmptyIcon/>))}
          sx={{backgroundColor: item.row.lockDate ? "error.light" : (item.row.consumptionDate ? "success.light" : (new Date(item.row.expirationDate) < new Date() ? "infoLight.light" : "ornament.main")), py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: item.row.lockDate ? "error.main" : (item.row.consumptionDate ? "success.main" : (new Date(item.row.expirationDate) < new Date() ? "info.main" : "neutral.main"))}}}
          label={<Typography variant="body2" color={item.row.lockDate ? "error.dark" : (item.row.consumptionDate ? "success.dark" : (new Date(item.row.expirationDate) < new Date() ? "info.dark" : "neutral.dark"))} sx={{fontWeight: "regular"}}>
            {item.row.lockDate ? t(`accessCodes.columns.lockDateSet`, {date: moment(item.row.lockDate).format("DD/MM/YYYY")}) : (item.row.consumptionDate ? t(`accessCodes.columns.consumptionDateUsed`, {date: moment(item.row.consumptionDate).format("DD/MM/YYYY")}) : (new Date(item.row.expirationDate) < new Date() ? t(`accessCodes.columns.expirationDatePassed`, {date: moment(item.row.expirationDate).format("DD/MM/YYYY")}) : t(`accessCodes.columns.consumptionDateUnused`)))}
          </Typography>}/>
      }
    }
  }

  const columns = [
    columnDefinition(t, "accessCodes", "code", 240, ColumnType.COPY, false),
    amountColumnDefinition(),
    consumptionDateColumnDefinition(),
    columnDefinition(t, "accessCodes", "expirationDate", 150, ColumnType.DATE),
    columnDefinition(t, "accessCodes", "description", 240, ColumnType.STRING),
    columnDefinition(t, "accessCodes", "createdAt", 150, ColumnType.DATE),
    operationAdminNameColumnDefinition(),
    columnDefinition(t, "accessCodes", "consumptionDate", 170, ColumnType.DATE_TIME),
    columnDefinition(t, "accessCodes", "lockDate", 170, ColumnType.DATE_TIME),
    actionColumn(params => [
      ...((params.row.operation == null) ? [] : [gridAction(params, "update", t(`accessCodes.actions.update`), EditIcon, updateCodeButton, false, params.row.consumptionDate != null)]),
      ...((params.row.operation == null) ? [] : [gridAction(params, "lock", params.row.lockDate == null ? t("accessCodes.actions.lock") : t("accessCodes.actions.unlock"), params.row.lockDate == null ? NotInterestedIcon : CheckCircleOutlineIcon, () => { lockCodeButton(params.row, params.row.lockDate == null ? true : false) }, true, params.row.consumptionDate != null)]),
      ...((params.row.operation == null) ? [] : [gridAction(params, "delete", t("accessCodes.actions.delete"), DeleteIcon, deleteCodeButton, false, params.row.consumptionDate != null)]),
    ])
  ]
  
  return (
    <List
      height="100%"
      alerts={alerts}
      apiRef={apiRef}
      reload={reload}
      columns={columns}
      enableToolbar={true}
      loadRows={loadCodes}
      setAlerts={setAlerts}
      setReload={setReload}
      filters={quickFilters}
      enableSelection={true}
      sx={{width: "100%", mb: 3}}
      emptyListIcon={emptySearch}
      translationPrefix="accessCodes"
      dialogs={[deleteDialog, lockDialog]}
      panels={[codePanel, deletedCodePanel]}
      defaultVisibilityModel={visibilityModel}
      defaultOrder={{field: 'createdAt', sort: 'desc'}}
      globalActions={[createAction, quickFilterAction, euroToPointAction]}
      selectedActions={[lockCodes, unlockCodes, deleteCodes]}
      listActions={[exportAction, openDeletedPanelAction]}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setSearchValue={setSearch}
      total={operationCodeCountWithFilters}
    />
  )
}
