import React, {useEffect, useState} from "react";
import {alpha, Box, IconButton, Slide, Typography} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {LibertyTheme} from "../../tokens/libertyTheme";

interface PanelProps {
  size: PanelSize;
  title: string;
  open: boolean;
  close: () => void;
  children: JSX.Element|JSX.Element[];
}

export enum PanelSize {
  HUGE = "75vw",
  BIG = "864px",
  SMALL = "490px"
}

export default function Panel(props: PanelProps): JSX.Element {
  const {size, title, open, close, children} = props;
  const [closeLocale, setCloseLocale] = useState(open);

  const closePanel = (): void => {
    setCloseLocale(false);
    setTimeout(close, 1000);
  }

  useEffect(() => {
    if (!open) {
      closePanel()
    }
  }, [open])

  return (
    <Box sx={{backgroundColor: alpha(LibertyTheme.palette.neutral.dark, 0.9), display: "flex", justifyContent: "flex-end", zIndex: 100,
      width: "100vw", position: "fixed", top: 0, left: 0}}>
      <Slide direction="left" in={closeLocale} mountOnEnter unmountOnExit>
        <Box sx={{display: "flex", flexDirection: "column", height: "100vh", overflowY: "auto", width: size, backgroundColor: "ornament.main"}}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", p: 5,
            backgroundColor: "ornament.light", borderBottom: "1px solid", borderColor: "ornament.dark"}}>
            <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: title}}/>
            <IconButton onClick={closePanel}>
              <ClearIcon sx={{width: 24, height: 24, color: "neutral.main"}}/>
            </IconButton>
          </Box>
          {children}
        </Box>
      </Slide>
    </Box>
  )
}
