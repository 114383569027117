import {Box, Tooltip} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {ImageRestriction, FixedCropper, FixedCropperRef, Cropper, CropperRef, Coordinates} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'
import {PrimaryBigButton, SecondaryBigButton, SecondarySmallButton} from '../../components/buttons/mainButton';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
// import FlipIcon from '@mui/icons-material/Flip';
import LoopIcon from '@mui/icons-material/Loop';
import {IconPosition} from '../../components/buttons/buttonProps';
import {Loader} from '../../components/loader/loader';
import {useTranslation} from "react-i18next";
interface CropImageProps {
    type: 'logo' | 'banner' | 'favicon';
    file: null|File;
    size: {width: number, height: number};
    setStep: (open: number) => void;
    uploadFile: (file: File | null, settings: CropSettingsProps) => void;
}

export interface CropSettingsProps {
  cropSettings: Coordinates,
  rotateSettings: number
}

export default function CropImageElement(props: CropImageProps): JSX.Element {
  const {t} = useTranslation();
  const {type, file, size, setStep, uploadFile} = props;
  const cropperRef = useRef<CropperRef>(null);
  const fixedCropperRef = useRef<FixedCropperRef>(null);
  
  const [loading, setLoading] = useState<boolean>(false);

  const cropSize = size.width > size.height ? size.width : size.height;

  const defaultSize = (): any => {
    return {
      width: type === "favicon" ? cropSize : size.width,
      height: type === "favicon" ? cropSize : size.height,
    };
  }

  useEffect(() => {
    if (loading) {
      onCrop();
    }
  }, [loading])

  const onCrop = (): void => {    
    if (file) {
      const cropper = type === "favicon" ? fixedCropperRef.current : cropperRef.current;
      if (cropper) {
        const cropSettings = cropper.getCoordinates();
        const rotateSettings = cropper.getTransforms();
        
        if (cropSettings && rotateSettings) {          
          uploadFile(file, {cropSettings: cropSettings, rotateSettings: rotateSettings["rotate"]});
        }
      }
    }
  };

  const reset = (): void => {
    type === "favicon" ? fixedCropperRef.current && fixedCropperRef.current.reset() : cropperRef.current && cropperRef.current.reset();
  };

  const rotate = (angle: number): void => {
    type === "banner" ? fixedCropperRef.current && fixedCropperRef.current.rotateImage(angle) : cropperRef.current && cropperRef.current.rotateImage(angle);
  };
  const zoom = (zoom: number): void => {
    type === "banner" ? fixedCropperRef.current && fixedCropperRef.current.zoomImage(zoom) : cropperRef.current && cropperRef.current.zoomImage(zoom);
  };
  /* const flip = (horizontal: boolean, vertical: boolean) => {
        if (cropperRef.current) {
            cropperRef.current.flipImage(horizontal, vertical);
        }
    };*/

  return file ?
    <Box sx={{width: "100%"}}>
      <Box sx={{display: loading ? "none" : "block", width: "100%", ".advanced-cropper": {"background-color": "#7F7F7F"}}}>
        {type === "favicon" ?
          <FixedCropper
            src={URL.createObjectURL(file)}
            ref={fixedCropperRef}
            style={{height: "60vh", width: "100%"}}
            imageRestriction={ImageRestriction.none}
            stencilSize={{
              width: 400,
              height: 400
            }}
            defaultSize={defaultSize}
            stencilProps={{
              handlers: true,
              lines: true,
              movable: true,
              resizable: true,
            }}
          />
          :
          <Cropper
            src={URL.createObjectURL(file)}
            ref={cropperRef}
            style={{height: "60vh", width: "100%"}}
            defaultSize={defaultSize}
            imageRestriction={ImageRestriction.none}
          />
        }
        <Box sx={{width: "100%", display: "flex", justifyContent: "center", mt: 3}}>
          <Tooltip title={t("cropperSettings.rotate")}><div><SecondarySmallButton icon={RotateRightIcon} position={IconPosition.LEFT} sx={{margin: "0 10px 0 10px", height: "48px", width: "48px", p: "6px 6px 6px 6px", ".MuiButton-startIcon": {margin: "0 0 0 0"}, ".MuiSvgIcon-root": {width: "30px", height: "30px"}}} action={(): void => { rotate(90) }} /></div></Tooltip>
          <Tooltip title={t("cropperSettings.unrotate")}><div><SecondarySmallButton icon={RotateLeftIcon} position={IconPosition.LEFT} sx={{margin: "0 10px 0 10px", height: "48px", width: "48px", p: "6px 6px 6px 6px", ".MuiButton-startIcon": {margin: "0 0 0 0"}, ".MuiSvgIcon-root": {width: "30px", height: "30px"}}} action={(): void => { rotate(-90) }} /></div></Tooltip>
          <Tooltip title={t("cropperSettings.zoom")}><div><SecondarySmallButton icon={ZoomInIcon} position={IconPosition.LEFT} sx={{margin: "0 10px 0 10px", height: "48px", width: "48px", p: "6px 6px 6px 6px", ".MuiButton-startIcon": {margin: "0 0 0 0"}, ".MuiSvgIcon-root": {width: "30px", height: "30px"}}} action={(): void => zoom(1.25)} /></div></Tooltip>
          <Tooltip title={t("cropperSettings.unzoom")}><div><SecondarySmallButton icon={ZoomOutIcon} position={IconPosition.LEFT} sx={{margin: "0 10px 0 10px", height: "48px", width: "48px", p: "6px 6px 6px 6px", ".MuiButton-startIcon": {margin: "0 0 0 0"}, ".MuiSvgIcon-root": {width: "30px", height: "30px"}}} action={(): void => zoom(0.8)} /></div></Tooltip>
          <Tooltip title={t("cropperSettings.reset")}><div><SecondarySmallButton icon={LoopIcon} position={IconPosition.LEFT} sx={{margin: "0 10px 0 10px", height: "48px", width: "48px", p: "6px 6px 6px 6px", ".MuiButton-startIcon": {margin: "0 0 0 0"}, ".MuiSvgIcon-root": {width: "30px", height: "30px"}}} action={(): void => reset()} /></div></Tooltip>
          {/* <SecondarySmallButton icon={FlipIcon} position={IconPosition.LEFT} sx={{mr: 3, p: "6px 6px 6px 6px", ".MuiButton-startIcon": {margin: "0 0 0 0"}}} action={() => flip(true, false)} />
          <SecondarySmallButton icon={FlipIcon} position={IconPosition.LEFT} sx={{mr: 3, p: "6px 6px 6px 6px", transform: "rotate(90deg)", ".MuiButton-startIcon": {margin: "0 0 0 0"}}} action={() => flip(false, true)} />*/}
        </Box>
      </Box>
      {loading && <Loader height={"60vh"} width={"100%"} />}
      <Box sx={{mt: 3, width: "100%", display: "flex", justifyContent: "end"}}>
        <SecondaryBigButton sx={{mr: 3}} disabled={loading} label={"Annuler"} action={(): void => setStep(1)} />
        <PrimaryBigButton disabled={loading} label={"Valider"} action={(): void => setLoading(true)} />
      </Box>
    </Box>
    : <></>;
}
