import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {trackPromise} from "react-promise-tracker";
import {useTranslation} from "react-i18next";
import {Alert, Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography} from "@mui/material";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {Loader} from "../../components/loader/loader";
import {errorManager, manageStringError, Violation} from "../../common/methods/ApiService";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {TAccessCode, TAccessCodeCreate} from "../../interfaces/accessCode";
import {createAccessCode, updateAccessCode} from "../../services/AccessCodeService";
import {TOperation} from "../../interfaces/operation";
import {formatAmount} from "../../utils/formatAmount";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {fr} from "date-fns/locale";
import moment from "moment";
import {getCompany} from "../../services/CompanyService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {UPDATE_ALL_WALLETS} from "../../common/methods/context-setter/globals";
import {TCompanyWallet} from "../../interfaces/company";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type CodeFormProps = {
  addSuccess: (success: string) => void;
  addError: (error: string) => void;
  setClose: (close: boolean) => void;
  setReload: (reload: boolean) => void;
  operation: TOperation;
  code?: TAccessCode | null;
};

export default function CodeForm(props: CodeFormProps): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {addSuccess, addError, setClose, setReload, operation, code} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [loading, setLoading] = useState<boolean>(true);
  const [violations, setViolations] = useState<Violation[]>([]);
  const [error, setError] = useState<string|null>(null);
  const [selectedWallet, setSelectedWallet] = useState<TCompanyWallet|undefined>(store.global.company.defaultWallet ? store.global.company.defaultWallet : undefined);
  const [expirationDate, setExpirationDate] = useState<string|undefined>();
  const [border, setBorder] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [count, setCount] = useState<number>(1);
  const [amountForm, setAmountForm] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    setAmount(code?.reward?.amount ? code.reward.amount : 0);
    setSelectedWallet(code?.reward?.wallet ? getWalletById(code?.reward?.wallet['@id']) : (store.global.company.defaultWallet ? store.global.company.defaultWallet : undefined));
    setExpirationDate(code?.expirationDate ? code.expirationDate : (moment(store.global.currentOperation?.expirationDate) < moment().add(1, 'years') ? store.global.currentOperation?.expirationDate : moment().add(1, 'years').format('YYYY-MM-DD')));
    setDescription(code?.description ? code.description : "");
    setAmountForm(code?.reward ? true : false);
    setLoading(false);
  }, [])

  const updateExpirationDate = (expirationDate: any): void => {    
    setExpirationDate(expirationDate ? moment(expirationDate).format("YYYY-MM-DD") : "");
  }

  const updateWallets = (): void => {
    getCompany(store.global.company.id)
      .then(company => {
        if (company) {
          globalStoreReducer(STORE, {type: UPDATE_ALL_WALLETS, wallets: company.wallets});
        }
      })
      .catch(error => manageStringError(error, addError, t))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault();
    setLoading(true);

    if (!operation.id) {
      navigate(PRIVATE_URL.LANDING_PAGE, {replace: true});
      return;
    }

    const record: TAccessCodeCreate = {}
    if (count) {
      record.count = count
    }
    if (amountForm && amount) {
      record.amount = amount
    }
    if (amountForm && selectedWallet) {
      record.wallet = selectedWallet['@id'].replace('/bo/wallets/','')
    }
    if (expirationDate) {
      record.expirationDate = expirationDate
    }
    if (description != "") {
      record.description = description
    }

    const call = (): Promise<TAccessCode> => (code) ? updateAccessCode(code.id, record) : createAccessCode(operation.id, record);

    trackPromise(
      call()
        .then(() => {
          updateWallets();
          setReload(true);
          addSuccess(code ? t("accessCodes.actions.update_success") : t("accessCodes.actions.create_success"));
          setClose(true);
        })
        .catch(async error => {
          const errorResult = errorManager(error, t, STORE, navigate);
          if (typeof errorResult === 'string') setError(errorResult);
          else if (Array.isArray(errorResult)) setViolations(errorResult);
        })
        .finally(() => setLoading(false))
    );
  }

  const displayWalletSelector = store.global.company.wallets.length > 2;

  const getWalletById = (id: string): TCompanyWallet|undefined => {
    let walletResult = undefined;
    store.global.company.wallets.map((wallet) => {
      if (wallet['@id'] == id) {        
        walletResult = wallet;
      }
    })
    return walletResult;
  }

  const handleWalletChange = (event: SelectChangeEvent): void => {
    const newValue = event.target.value;    
    setSelectedWallet(getWalletById(newValue));
  }

  const handleNavigation = (): void => {
    if (selectedWallet && selectedWallet.balance < (amount*count)) {
      navigate(PRIVATE_URL.WALLETS_BUY_POINTS);
    }   
  }

  const menuStyle = {
    py: 1, px: 3,
    fontSize: LIBERTY_THEME.typography.body2.fontSize,
    lineHeight: LIBERTY_THEME.typography.body2.lineHeight,
    overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
  }

  return loading ? <Loader/> :
    <Box component="form" onSubmit={handleSubmit} sx={{position: "relative", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <Box sx={{backgroundColor: "ornament.main", display: "flex", flexDirection: "column", justifyContent: "space-between", mx: 5, mt: 5}}>
        {!code && <Box sx={{mb: 2}}>
          <Typography variant="body2" sx={{fontWeight: "bold", mb: "4px", color: "neutral.main"}}>{t('accessCodes.form.label.count')}</Typography>
          <TextField onChange={(event: any): void => !isNaN(event.target.value)?setCount(Math.abs(event.target.value)):setCount(0)} type="number" color="primary"
            value={count}
            error={violations?.find((violation: any) => violation.field === "count")?.error !== undefined}
            helperText={violations?.find((violation: any) => violation.field === "count")?.error}
            sx={{width: '100%', backgroundColor: "ornament.light", mr: 2, "& input": {p: 3}}}
            defaultValue={count}
          />
        </Box>}
        <Box sx={{mb: 2}}>
          <Typography variant="subtitle2">
            {t("accessCodes.form.label.expirationDate")}
          </Typography>
          <Box sx={{".MuiFormControl-root": {backgroundColor: "ornament.light"}}}>
            <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
              <DatePicker
                value={expirationDate}
                disabled={loading}
                onChange={(newDate: any): void => {
                  updateExpirationDate(newDate)
                }}
                onOpen={(): void => setBorder(true)}
                onClose={(): void => setBorder(false)}
                PopperProps={{
                  sx: {borderWidth: border ? '1px' : '0px', borderStyle: "solid", borderColor: "primary.main", borderRadius: "4px"}
                }}
                renderInput={(params): any => <TextField {...params} inputProps={{...params.inputProps, placeholder: "jj/mm/aaaa"}} sx={{width: "100%", "& input": {p: 3}}} />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box>
          <Typography variant="body2" sx={{fontWeight: "bold", mb: "4px", color: "neutral.main"}}>{t('accessCodes.form.label.description')}</Typography>
          <TextField
            sx={{width: "100%", backgroundColor: "#fff"}}
            multiline
            value={description??""} 
            onChange={(e): void => setDescription(e.target.value)}
            rows={4}
          />
        </Box>
        <Box sx={{my: 4, display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer"}} onClick={(): void => { setAmountForm(!amountForm), setAmount(0), setSelectedWallet(store.global.company.defaultWallet ? store.global.company.defaultWallet : undefined) }}>
          <Typography variant="body1" sx={{fontWeight: "bold", color: "primary.main"}}>{t('accessCodes.form.switch')}</Typography>
          <Switch checked={amountForm}/>
        </Box>
        {amountForm && <Box>
          <Box>
            <Typography variant="body2" sx={{fontWeight: "bold", mb: "4px", color: "neutral.main"}}>{t('accessCodes.form.label.amount')}</Typography>
            <TextField onChange={(event: any): void => !isNaN(event.target.value)?setAmount(Math.abs(event.target.value)):setAmount(0)} type="string" color="primary"
              value={amount}
              error={violations?.find((violation: any) => violation.field === "amount")?.error !== undefined}
              helperText={violations?.find((violation: any) => violation.field === "amount")?.error}
              sx={{width: '100%', backgroundColor: "ornament.light", mr: 2, "& input": {p: 3}}}
              defaultValue={amount}
              InputProps={{
                lang: "fr-FR",
                endAdornment: <InputAdornment position="end">
                  <Typography color="neutral.dark" variant="body2">{t('accessCodes.form.unit')}</Typography>
                </InputAdornment>,
                inputProps: {
                  min: 1,
                }
              }}
            />
          </Box>
          {displayWalletSelector && selectedWallet &&
            <Box>
              <Typography variant="subtitle2" sx={{mt: 2, mb: 1}}>
                {t("wallets.import.which_wallet")}
              </Typography>
              <Select
                color="primary"
                displayEmpty
                sx={{width: "100%", backgroundColor: "ornament.light", "& .MuiSelect-select": {display: "flex", alignItems: "center"}}}
                name={'points-wallet'}
                id={"select-wallet"}
                value={selectedWallet['@id']}
                onChange={handleWalletChange}
                disabled={loading}
              >
                {store.global.company.wallets.map((wallet, i) => (
                  (store.global.company.expiredPointsWallet && wallet.id !== store.global.company.expiredPointsWallet.id) &&
                    <MenuItem key={`wallet-${i}`} value={wallet['@id']} sx={{color: "neutral.main", ...menuStyle}}>
                      {(wallet.name == "default" ? t("operations.points_balance.wallets.default") : wallet.name)}
                    </MenuItem>
                ))}
              </Select>
              <Box sx={{mt: 0, display: "flex", alignItems: "center", cursor: `${selectedWallet.balance < (amount*count) ? "pointer" : "default"}`}} onClick={handleNavigation}>
                <InfoOutlinedIcon sx={{color: selectedWallet.balance < (amount*count) ? "warning.main" : "info.main", mr: 1}} />
                <Typography variant="body2" color={`${selectedWallet.balance < (amount*count) ? "warning.main" : "info.main"}`} dangerouslySetInnerHTML={{__html: selectedWallet.balance < (amount*count) ? t('accessCodes.form.warning_wallet', {amount: formatAmount((amount*count) - selectedWallet.balance)}) : t('accessCodes.form.info_wallet', {amount: formatAmount(selectedWallet.balance - (amount*count))})}} />
              </Box>
            </Box>
          }
        </Box>}
        {error && <Alert variant="filled" severity="error" sx={{mt: 3}}>{error}</Alert>}
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", zIndex: 1, backgroundColor: "ornament.light"}}>
        <Box sx={{display: "flex", px: 5, py: 3, borderTop: "1px solid", borderColor: "ornament.dark"}}>
          <SecondaryBigButton action={(): void => setClose(true)} sx={{mr: 3}} label={t("navigation.cancel")}/>
          <PrimaryBigButton submit={true} sx={{width: "100%"}} label={code ? t("accessCodes.form.update") : t("accessCodes.form.create")}/>
        </Box>
      </Box>
    </Box>
}
