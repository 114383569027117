import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import {RequestFilter, RequestFilterOperator, RequestOrder, errorManager, manageStringError} from "../../common/methods/ApiService";
import List from "../../patterns/list/list";
import emptySearch from "../../assets/lists/empty_search.svg";
import {
  actionColumn,
  columnDefinition,
  ColumnType,
  gridAction,
  valueGetter
} from "../../patterns/list/listUtils";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {EuroPointToggle, PointsUnit} from "../../patterns/list/euroPointToggle";
import {Box, Chip, Typography} from "@mui/material";
import {getAllOperationCodesDeleted, getOperationCodesDeleted, restoreOperationCodes} from "../../services/AccessCodeService";
import {TAccessCode} from "../../interfaces/accessCode";
import {TOperation} from "../../interfaces/operation";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment';
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {useNavigate} from "react-router-dom";
import RestoreDialog from "../../patterns/dialog/restoreDialog";
import AutoRenewIcon from "@mui/icons-material/Autorenew";
import {SecondarySmallButton} from "../../components/buttons/mainButton";
import {IconPosition} from "../../components/buttons/buttonProps";
import {formatIds} from "../../common/methods/utils";

interface ListDeletedCodeProps {
  addSuccess: (success: string) => void;
  addError: (error: string) => void;
  setClose: (close: boolean) => void;
  operation: TOperation;
  setReloadList: (reloadList: boolean) => void;
}

export default function ListDeletedCodes(props: ListDeletedCodeProps): JSX.Element {
  const {operation, addSuccess, addError, setReloadList} = props;
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const [selectedUnit, setSelectedUnit] = useState(PointsUnit.EUROS);
  const [operationCode, setOperationCode] = useState<TAccessCode|null>(null);
  const [openRestoreDialog, setOpenRestoreDialog] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number|null>(null);
  const [search, setSearch] = useState("");
  const [operationCodeDeletedCountWithFilters, setOperationCodeDeletedCountWithFilters] = useState(0);

  const euroToPointAction = <Box key={Math.random()} sx={{mr: 1}}><EuroPointToggle selected={selectedUnit} setSelected={setSelectedUnit}/></Box>

  const restoreCodeAction = async (): Promise<void> => {
    if (operationCode == null && (selectedRows == 0 || selectedRows == null)) {
      return Promise.resolve();
    }

    let filters: RequestFilter[]|null = [];   
    let count = 1; 
    if (!selectAll && selectedRows != 0 && selectedRows != null) {
      filters = formatIds(Array.from(apiRef.current.getSelectedRows().keys()).map(key => key.toString()));  
      count = selectedRows > 1 ? selectedRows : 1;    
    } else if (!selectAll && (selectedRows == 0 || selectedRows == null) && operationCode) {      
      filters = formatIds([operationCode.id]);
    } else if (selectAll) {
      try {
        const res = await getAllOperationCodesDeleted(operation.id, search, null, [{field: "pagination", items: [{value: "false", operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]}]);
        const ids = res.map(item => item.id);
        filters = formatIds(ids);          
        count = filters[0].items.length > 1 ? filters[0].items.length : 1;    
      } catch (error: any) {
        manageStringError(error, addError, t);
      }
    } 

    restoreOperationCodes(operation.id, filters)
      .then(() => {
        setReload(true);
        setReloadList(true);
        setOpenRestoreDialog(false);
        addSuccess(t("accessCodes.actions.restore_success", {count: count}));
      })
      .catch(error => manageStringError(errorManager(error, t, STORE, navigate), addError, t));
  }

  const restoreCodes = <SecondarySmallButton key="restore-codes" label={t("accessCodes.actions.restore_codes")} icon={AutoRenewIcon} sx={{ml: 1}}
    position={IconPosition.LEFT} action={(): void => { setOpenRestoreDialog(true) }}/>

  const operationAdminNameColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "accessCodes", "admin.name", 150, ColumnType.STRING, true, false, false),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.admin['name'], ColumnType.STRING)
    }
  }

  const amountColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "accessCodes", selectedUnit == PointsUnit.POINTS ? "reward.pointAmount" : "reward.amount", 140, selectedUnit == PointsUnit.POINTS ? ColumnType.POINTS : ColumnType.EUROS, true),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.reward ? (selectedUnit == PointsUnit.POINTS ? item.row.reward.pointAmount : item.row.reward.amount) : "0", selectedUnit == PointsUnit.POINTS ? ColumnType.POINTS : ColumnType.EUROS)
    }
  }

  const consumptionDateColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "accessCodes", "status", 300, ColumnType.DATE_TIME),
      renderCell: (item): JSX.Element => {
        return <Chip icon={item.row.lockDate ? <CloseIcon/> : (item.row.consumptionDate ? <CheckIcon/> : (new Date(item.row.expirationDate) < new Date() ? <ErrorIcon/> : <HourglassEmptyIcon/>))}
          sx={{backgroundColor: item.row.lockDate ? "error.light" : (item.row.consumptionDate ? "success.light" : (new Date(item.row.expirationDate) < new Date() ? "infoLight.light" : "ornament.main")), py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: item.row.lockDate ? "error.main" : (item.row.consumptionDate ? "success.main" : (new Date(item.row.expirationDate) < new Date() ? "info.main" : "neutral.main"))}}}
          label={<Typography variant="body2" color={item.row.lockDate ? "error.dark" : (item.row.consumptionDate ? "success.dark" : (new Date(item.row.expirationDate) < new Date() ? "info.dark" : "neutral.dark"))} sx={{fontWeight: "regular"}}>
            {item.row.lockDate ? t(`accessCodes.columns.lockDateSet`, {date: moment(item.row.lockDate).format("DD/MM/YYYY")}) : (item.row.consumptionDate ? t(`accessCodes.columns.consumptionDateUsed`, {date: moment(item.row.consumptionDate).format("DD/MM/YYYY")}) : (new Date(item.row.expirationDate) < new Date() ? t(`accessCodes.columns.expirationDatePassed`, {date: moment(item.row.expirationDate).format("DD/MM/YYYY")}) : t(`accessCodes.columns.consumptionDateUnused`)))}
          </Typography>}/>
      }
    }
  }

  const columns = [
    columnDefinition(t, "accessCodes", "code", 240, ColumnType.STRING, false),
    amountColumnDefinition(),
    columnDefinition(t, "accessCodes", "expirationDate", 170, ColumnType.DATE_TIME),
    columnDefinition(t, "accessCodes", "description", 240, ColumnType.STRING),
    columnDefinition(t, "accessCodes", "createdAt", 170, ColumnType.DATE_TIME),
    operationAdminNameColumnDefinition(),
    consumptionDateColumnDefinition(),
    columnDefinition(t, "accessCodes", "lockDate", 170, ColumnType.DATE_TIME),
    actionColumn(params => [
      gridAction(params, "restore_attribute", t(`accessCodes.actions.restore`), AutoRenewIcon, (code: TAccessCode): void => {
        setOperationCode(code);
        setOpenRestoreDialog(true);
      })
    ])
  ]

  const loadAttributes = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TAccessCode[]}> => {
    return getOperationCodesDeleted(operation.id, page, maxItems, search, order, filters).then((res) => {
      setOperationCodeDeletedCountWithFilters(res.totalItems);
      return res;
    });
  }

  const restoreCodeDialog = <RestoreDialog
    key="restore-code"
    title={t("accessCodes.actions.restore_title", {count: selectedRows != null ? selectedRows : 1})}
    open={openRestoreDialog}
    close={(): void => setOpenRestoreDialog(false)}
    info={t("accessCodes.actions.restore_warning", {count: selectedRows != null ? selectedRows : 1})}
    restoreElement={restoreCodeAction}/>

  return <List
    sx={{mt: 5, mx: 5, gridColumn: "1/5"}}
    translationPrefix="accessCodes"
    columns={columns}
    apiRef={apiRef}
    loadRows={loadAttributes}
    globalActions={[euroToPointAction]}
    selectedActions={[restoreCodes]}
    reload={reload}
    setReload={setReload}
    emptyListIcon={emptySearch}
    height="100%"
    dialogs={[restoreCodeDialog]}
    selectAll={selectAll}
    setSelectAll={setSelectAll}
    selectedRows={selectedRows}
    setSelectedRows={setSelectedRows}
    setSearchValue={setSearch}
    total={operationCodeDeletedCountWithFilters}
  />
}
