import React, {useState} from 'react';
import {Box, TextField, Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from "react-i18next";
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {IconPosition} from '../../components/buttons/buttonProps';
import {postEmailContact} from '../../services/EmailContactService';

export function DesktopForm() : JSX.Element {

  const {t} = useTranslation();

  const [errors, setErrors] = useState<any>({});
  const [emailSend, setEmailSend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
  });

  const handleChange = (e: any): void => {
    const {name, value} = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const sendEmailContact = async (): Promise<void> => {
    setLoading(true);
    postEmailContact(formData)
      .then((res: any) => {        
        if (!res) {
          setEmailSend(true);
          setLoading(false);
        } else if (res.errors) {
          setErrors(res.errors);
          setLoading(false);
        }
      });
  }

  const breakpoint = useMediaQuery('(min-width: 1100px)');

  return (
    <>
      <Typography variant="h1" sx={{color: "#50DBB9", width: "fit-content", fontWeight: "medium", mb: 4}} dangerouslySetInnerHTML={{__html: t("landing_page.form.title")}} />
      <Box sx={{display: "flex", alignItems: "start", gap: "20px", width: "100%", flexDirection: breakpoint ? "row" : "column"}}>
        <Box sx={{width: breakpoint ? "15%" : "100%"}}>
          <Typography variant="body1" sx={{color: "ornament.main", mb: 1}}>{t("landing_page.form.field.firstname")}</Typography>
          <TextField name="lastname" value={formData.lastname} onChange={handleChange} sx={{backgroundColor: "ornament.main", borderRadius: "3px", width: "100%", borderColor: "#15154B", borderStyle: "solid", borderWidth: "2px", "& fieldset": {border: 'none'}}} size="small"></TextField>
        </Box>
        <Box sx={{width: breakpoint ? "15%" : "100%"}}>
          <Typography variant="body1" sx={{color: "ornament.main", mb: 1}}>{t("landing_page.form.field.lastname")}</Typography>
          <TextField name="firstname" value={formData.firstname} onChange={handleChange} sx={{backgroundColor: "ornament.main", borderRadius: "3px", width: "100%", borderColor: "#15154B", borderStyle: "solid", borderWidth: "2px", "& fieldset": {border: 'none'}}} size="small"></TextField>
        </Box>
        <Box sx={{width: breakpoint ? "25%" : "100%"}}>
          <Typography variant="body1" sx={{color: "ornament.main", mb: 1}}>{t("landing_page.form.field.company")}</Typography>
          <TextField name="company" value={formData.company} onChange={handleChange} sx={{backgroundColor: "ornament.main", borderRadius: "3px", width: "100%", borderColor: errors.company ? "error.main" : "#15154B", borderStyle: "solid", borderWidth: "2px", "& fieldset": {border: 'none'}}} size="small"></TextField>
          {errors.company && <Typography variant="body1" sx={{color: "error.main", mt: 1}}>{errors.company}</Typography>}
        </Box>
        <Box sx={{width: breakpoint ? "30%" : "100%"}}>
          <Typography variant="body1" sx={{color: "ornament.main", mb: 1}}>{t("landing_page.form.field.email")}</Typography>
          <TextField name="email" value={formData.email} onChange={handleChange} sx={{backgroundColor: "ornament.main", borderRadius: "3px", width: "100%", borderColor: errors.email ? "error.main" : "#15154B", borderStyle: "solid", borderWidth: "2px", "& fieldset": {border: 'none'}}} size="small"></TextField>
          {errors.email && <Typography variant="body1" sx={{color: "error.main", mt: 1}}>{errors.email}</Typography>}
        </Box>
        <Box sx={{width: breakpoint ? "15%" : "100%"}}>
          <Typography variant="body1" sx={{color: "ornament.main", mb: 1}}>{t("landing_page.form.field.phone")}</Typography>
          <TextField name="phone" value={formData.phone} onChange={handleChange} sx={{backgroundColor: "ornament.main", borderRadius: "3px", width: "100%", borderColor: errors.phone ? "error.main" : "#15154B", borderStyle: "solid", borderWidth: "2px", "& fieldset": {border: 'none'}}} size="small"></TextField>
          {errors.phone && <Typography variant="body1" sx={{color: "error.main", mt: 1}}>{errors.phone}</Typography>}
        </Box>
      </Box>
      <Box sx={{width: "100%", display: "flex", justifyContent: "end", mt: 4}}>
        {emailSend ? 
          <Box sx={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Typography variant="body1" sx={{color: "ornament.main"}}>{t("landing_page.form.send_message")}</Typography>
            <Box sx={{backgroundColor: "#50DBB9", boxSizing: "border-box", height: "42px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px", padding: "16px 24px"}}>
              <Typography variant="body1" sx={{color: "#15154B", fontWeight: "bold"}}>{t("landing_page.form.send_button")}</Typography>
            </Box>
          </Box> : 
          <PrimaryBigButton action={sendEmailContact} label={t("landing_page.form.button")} sx={{backgroundColor: "#50DBB9", color: "#15154B", height: "42px", "&:hover": {backgroundColor: "ornament.main", color: "#15154B"}, "& .Mui-disabled": {backgroundColor: "#50DBB9", color: "#15154B"}}} loading={loading} icon={ArrowForwardIcon} position={IconPosition.RIGHT}/>
        }
      </Box>
    </>
  )
}