export const API_ROOT_URL = process.env.REACT_APP_API_HOST;
export const API_BO_URL = API_ROOT_URL + '/bo';
export const GLOBAL_EXCLUDE_INVALID_LINES = "excludeInvalidLines=true";
export const GLOBAL_IMPORT_ID = "importId";
export const GLOBAL_OPERATION_ID = "operationId"
export const GLOBAL_PARTICIPANT_ID = "participantId"
export const GLOBAL_ADMIN_ID = "adminId"
export const GLOBAL_COMPANY_ID = "companyId"

export const operationUrl = (url: string, id: string): string => url.replace(':' + GLOBAL_OPERATION_ID, id);
export const participantUrl = (url: string, id: string): string => url.replace(':' + GLOBAL_PARTICIPANT_ID, id);
export const companyUrl = (url: string, id: string): string => url.replace(':' + GLOBAL_COMPANY_ID, id);

export const PUBLIC_URL = {
  FORGET_PASSWORD: "/mot-de-passe-oublie",
  RESET_PASSWORD: "/renouvellement-mot-de-passe",
  VALIDATE_EMAIL: "/validation-compte",
  REGISTER: "/inscription",
  LOGIN: "/connexion",
  CGVU: "/cgvu.pdf",
  PPDP: "/ppdp.pdf",
  MAINTENANCE_MODE_ON: "/maintenance",
}

export const FO_URL = {
  LOGIN: "/connexion"
}

export const HCAPTCHA_VERIFY = "https://hcaptcha.com/siteverify"

export const API_URL = {
  GET_DASHBOARD: "operations/:" + GLOBAL_OPERATION_ID + "/dashboard",
  REWARDS_PARTICIPANT: "rewards",
  INIT_PAYMENT_OPERATION: "wallettransactions/payment",
  WALLET_TRANSACTIONS: "wallettransactions",
  OPERATION: "operations",
  ALL_OPERATION: "all-operations",
  COMPANY: "companies",
  ADMIN: "admins",
  PARTICIPANT: "participants",
  PARTICIPANT_ATTRIBUTES: "participantattributes",
  PARTICIPANT_ATTRIBUTE_VALUES: "participantattributevalues",
  OPERATION_PARTICIPANTS: "operationparticipants",
  IMPORT_PARTICIPANTS: "participants-imports",
  VALIDATE_PARTICIPANTS: "participants-imports/:" + GLOBAL_IMPORT_ID + "/validate",
  CANCEL_PARTICIPANTS: "participants-imports/:" + GLOBAL_IMPORT_ID + "/cancel",
  SAVE_PARTICIPANTS: "participants-imports/:" + GLOBAL_IMPORT_ID + "/save",
  IMPORT_DOWNLOAD: "participants-imports/:" + GLOBAL_IMPORT_ID + "/download",
  MODEL_IMPORT_PARTICIPANTS: "participants-imports/participants.csv",
  ERROR_IMPORT_PARTICIPANTS: "participants-imports/:" + GLOBAL_IMPORT_ID + "/errors.csv",
  EXPORT_OPERATION_PARTICIPANTS: "operations/:"+ GLOBAL_OPERATION_ID + "/operationparticipants-export",
  EXPORT_OPERATION_DELETED_PARTICIPANTS: "operations/:"+ GLOBAL_OPERATION_ID + "/operationparticipants-deleted-export",
  EXPORT_COMPANY_POINTS_PURCHASE: "companies/:"+ GLOBAL_COMPANY_ID + "/wallettransactions-pointspurchase-export",
  EXPORT_COMPANY_WALLET_TRANSACTION: "companies/:"+ GLOBAL_COMPANY_ID + "/wallettransactions-export",
  EXPORT_OPERATION_REWARDS: "operations/:"+ GLOBAL_OPERATION_ID + "/operationrewards-export",
  EXPORT_OPERATION_CODES: "operations/:"+ GLOBAL_OPERATION_ID + "/operationcodes-export",
  EXPORT_OPERATION_GIFTS: "operations/:"+ GLOBAL_OPERATION_ID + "/operationgifts-export",
  SAVE_PARTICIPANTS_ERROR_LESS: "participants-imports/:" + GLOBAL_IMPORT_ID + "/save?" + GLOBAL_EXCLUDE_INVALID_LINES,
  IMPORT_PARTICIPANT_ATTRIBUTE_TYPES: "participants-imports/attribute-types",
  IMPORT_LOGO_OPERATION: "operations/:" + GLOBAL_OPERATION_ID + "/upload-logo",
  CREATE_OPERATION: "operations",
  REGISTER: "admins/register",
  INVITE: "admins/invite",
  RESET_PASSWORD: "admins/reset-password",
  VALIDATE_EMAIL: "admins/:" + GLOBAL_ADMIN_ID + "/validate-email",
  SEND_VALIDATION_EMAIL: "admins/:" + GLOBAL_ADMIN_ID + "/send-validation-email",
  LOGIN: "login",
  LOGIN_WITH_TOKEN: "login-with-token",
  REFRESH_TOKEN: "refresh-token",
  TEST: "https://reqres.in/api/posts",
  EMAIL_TEMPLATES: "emailtemplates",
  LOGGING_ENTRIES: "loggingentries",
  VIEW_PARTICIPANT_WARNING_CREDIT: "operations/:" + GLOBAL_OPERATION_ID + "/participants-rewarded-last-day",
  OPERATION_STRIPE_INTENT: "companies/:" + GLOBAL_COMPANY_ID + "/stripe-intent",
  OPERATION_PAYMENT_CB: "companies/:" + GLOBAL_COMPANY_ID + "/payment-cb",
  COOKIES: "cookies",
  WALLETS: "wallets",
  ACCESS_CODES: "accesscodes",
  ACCESS_CODE_DELETE: "operations/:" + GLOBAL_OPERATION_ID + "/accesscodes",
  ACCESS_CODE_DELETED: "operations/:" + GLOBAL_OPERATION_ID + "/access-codes-deleted",
  ACCESS_CODE_CREATE: "operations/:" + GLOBAL_OPERATION_ID + "/access-code",
  EMAIL_CONTACT: "email-contact"
}

export const PRIVATE_URL = {
  SELECT_COMPANY: "/selectionner-entreprise",
  COMPANY_ADMINISTRATORS: "/administrateurs-entreprise",
  BILLING_ADDRESS: "/adresse-facturation",
  BILLING_HISTORY: "/historique-facturation",
  COMPANY_HISTORY: "/historique-entreprise",
  FAQ: "/faq",
  EMAIL_TEMPLATES: "/template-email",
  USER_SETTINGS: "/parametre-utilisateur",
  UPLOAD_LOGO_MENU: "/operation/:" + GLOBAL_OPERATION_ID + "/charger-logo",
  IMPORT_PARTICIPANTS_WIZARD: "/operation/:" + GLOBAL_OPERATION_ID + "/participants-imports",
  LIST_OPERATION_PARTICIPANTS: "/operation/:" + GLOBAL_OPERATION_ID + "/participants-liste",
  LIST_OPERATION_CODES: "/operation/:" + GLOBAL_OPERATION_ID + "/codes",
  LIST_OPERATION_PARTICIPANT_ATTRIBUTES: "/operation/:" + GLOBAL_OPERATION_ID + "/participant-attributs-liste",
  IMPORT_OPERATION_PARTICIPANTS: "/operation/:" + GLOBAL_OPERATION_ID + "/participants-import",
  CREATE_OPERATION_WIZARD: "/operation/creer-wizard",
  CREATE_OPERATION_MENU: "/operation/creer-menu",
  ADD_EMAIL_TEMPLATE: "/template-email/ajout",
  UPDATE_EMAIL_TEMPLATE: "/template-email/:id/mettre-a-jour",
  OPERATION_POINTS_HISTORY: "/operation/:" + GLOBAL_OPERATION_ID + "/points-historique",
  OPERATION_CONFIG: "/operation/:" + GLOBAL_OPERATION_ID + "/configuration",
  OPERATION_GIFTS_HISTORY: "/operation/:" + GLOBAL_OPERATION_ID + "/cadeau-historique",
  OPERATION_CUSTOMISATION: "/operation/:" + GLOBAL_OPERATION_ID + "/personnalisation",
  OPERATION_CHARACTERISTICS: "/operation/:" + GLOBAL_OPERATION_ID + "/caracteristiques",
  OPERATION_SHOWCASE: "/operation/:" + GLOBAL_OPERATION_ID + "/vitrine",
  OPERATION_RULES: "/operation/:" + GLOBAL_OPERATION_ID + "/reglement",
  OPERATION_PRESENTATION: "/operation/:" + GLOBAL_OPERATION_ID + "/presentation",
  OPERATION_DASHBOARD: "/operation/:" + GLOBAL_OPERATION_ID + "/tableau-de-bord",
  OPERATION_SHOP_PRICE: "/operation/:" + GLOBAL_OPERATION_ID + "/prix-boutique",
  OPERATION_SHOP_CRITERIA: "/operation/:" + GLOBAL_OPERATION_ID + "/criteres-boutique",
  OPERATION_SHOP_UNIVERSES: "/operation/:" + GLOBAL_OPERATION_ID + "/univers-boutique",
  OPERATION_SHOP_GIFTS: "/operation/:" + GLOBAL_OPERATION_ID + "/cadeaux-boutique",
  OPERATION_SHOP_SETTINGS: "/operation/:" + GLOBAL_OPERATION_ID + "/parametres-boutique",
  OPERATION_EMAIL_SETTINGS: "/operation/:" + GLOBAL_OPERATION_ID + "/parametres-email",
  OPERATION_EMAIL_HISTORY: "/operation/:" + GLOBAL_OPERATION_ID + "/historique-email",
  OPERATION_EMAIL_PLANNED: "/operation/:" + GLOBAL_OPERATION_ID + "/emails-planifies",
  OPERATION_EMAIL_CUSTOMISATION: "/operation/:" + GLOBAL_OPERATION_ID + "/personnalisation-email",
  LANDING_PAGE: "/",
  WELCOME: "/page-bienvenue",
  PARTICIPANT_REWARDS: "/participant/:" + GLOBAL_PARTICIPANT_ID + "/recompense",
  SUPER_ADMIN_HOME: "/super-admin/home",
  SUPER_ADMIN_COMPANIES: "/super-admin/companies",
  SUPER_ADMIN_ADMINS: "/super-admin/admins",
  SUPER_ADMIN_OPERATIONS: "/super-admin/operations",
  WALLETS_BUY_POINTS: "/wallets/achat-points",
  WALLETS_ONGOING: "/wallets/achat-en-cours",
  WALLETS_LIST: "/wallets/liste",
  WALLETS_POINTS_MOVEMENTS: "/wallets/mouvements",
  TRANSACTION_PAYMENT: "/transactions/paiement",
  TRANSACTION_PAYMENT_CONFIRM: "/transactions/confirmation",
  TRANSACTION_PAYMENT_SUCCESS: "/transactions/success",
  TRANSACTION_ONGOING: "/operation/:" + GLOBAL_OPERATION_ID + "/achat-en-cours",
}
