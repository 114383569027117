import Box from "@mui/material/Box";
import {Alert, Card, Checkbox, FormControlLabel, Typography, useMediaQuery} from "@mui/material";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import {PRIVATE_URL, PUBLIC_URL} from "../../common/struct/urlManager";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {errorManager, Violation} from "../../common/methods/ApiService";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {registerAdmin} from "../../services/CompanyService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {LOGIN} from "../../common/methods/context-setter/globals";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export default function RegisterForm(props: {sx?: SxProps<Theme>}): JSX.Element {
  const {t} = useTranslation();
  const {sx} = props;
  const navigate = useNavigate();
  const STORE = useContext<StoreContext>(AppContext);

  const [violations, setViolations] = useState<Violation[]>([]);
  const [error, setError] = useState<string|null>(null);
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [legalChecked, setLegalChecked] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const adminToCreate = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone: phone,
      cgvuDate: legalChecked ? new Date() : null,
      company: {
        name: company
      }
    }
    registerAdmin(adminToCreate)
      .then(admin => {
        globalStoreReducer(STORE, {type: LOGIN, admin: admin});
        navigate(PRIVATE_URL.WELCOME);
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
        else if (Array.isArray(errorResult)) setViolations(errorResult);
      })
      .finally(() => setLoading(false))
  }

  const breakpoint = useMediaQuery('(min-width:1450px)');
  const breakpointS = useMediaQuery('(min-width:1100px)');
  const breakpointXS = useMediaQuery('(min-width:660px)');

  return (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", overflowY: "auto", width: breakpoint ? "60%" : breakpointS ? "70%" : "90%", mx: "auto", ...sx}}>
      <form onSubmit={handleSubmit} style={{width: "100%"}}>
        {error && <Alert severity="error" variant="filled" sx={{mb: 3}}>{error}</Alert>}
        <Card sx={{backgroundColor: "ornament.light", p: 6, mb: 6, border: "1px solid", borderColor: "ornament.dark"}}>
          <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", flexDirection: breakpointXS ? "row" : "column"}}>
            <ElementInputWithLabel required disabled={loading} label={t("register.form.lastName")} name="lastName" value={lastName} updateValue={setLastName} placeholder={t("register.form.lastName_placeholder")} violations={violations} sx={{width: breakpointXS ? "49%" : "100%"}}/>
            <ElementInputWithLabel required disabled={loading} label={t("register.form.firstName")} name="firstName" value={firstName} updateValue={setFirstName} placeholder={t("register.form.firstName_placeholder")} violations={violations} sx={{width: breakpointXS ? "49%" : "100%"}}/>
          </Box>
          <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", flexDirection: breakpointXS ? "row" : "column"}}>
            <ElementInputWithLabel required disabled={loading} label={t("register.form.company")} name="company.name" value={company} updateValue={setCompany} placeholder={t("register.form.company_placeholder")} violations={violations} sx={{width: breakpointXS ? "49%" : "100%"}}/>
            <ElementInputWithLabel disabled={loading} type={INPUT_TYPES.TELEPHONE} label={t("register.form.phone")} name="phone" value={phone} updateValue={setPhone} placeholder={t("register.form.phone_placeholder")} violations={violations} sx={{width: breakpointXS ? "49%" : "100%"}}/>
          </Box>
          <ElementInputWithLabel required disabled={loading} type={INPUT_TYPES.EMAIL} label={t("register.form.email")} name="email" value={email} updateValue={setEmail} placeholder={t("register.form.email_placeholder")} violations={violations}/>
          <ElementInputWithLabel required disabled={loading} canSubmit={canSubmit} setCanSubmit={setCanSubmit} type={INPUT_TYPES.PASSWORD} passwordCheck={true} label={t("register.form.password")} name="password" value={password} updateValue={setPassword} placeholder={t("register.form.password_placeholder")} violations={violations}/>
          <FormControlLabel
            name="made_ethics"
            sx={{m: "unset", mb: 5}}
            control={<Checkbox required checked={legalChecked} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={(): void => setLegalChecked(!legalChecked)}/>}
            label={<Typography variant="body1" color="neutral.dark" sx={{fontWeight: "medium", mx: 0, ml: 3, "a": {color: "primary.main"}}}>{t("register.legal.accept_label")}<Link to={PUBLIC_URL.CGVU} target={"_blank"} style={{fontWeight: 800, textDecoration: "none"}}>{t("register.legal.accept_label_link")}</Link></Typography>}
          />
          <PrimaryBigButton label={t("register.form.submit_button")} sx={{mt: 1, width: "100%"}} submit={true} loading={loading} disabled={!canSubmit || !lastName || !firstName || !company || !email || !legalChecked}/>
        </Card>
      </form>
    </Box>
  )
}
