import React, {useContext, useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ThirdLevelMenu from "../../patterns/menu/thirdLevelMenu";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ListOperationParticipants from "./listParticipants/listOperationParticipants";
import ListOperationParticipantAttributes from "./listAttributes/listOperationParticipantAttributes";
import ImportOperationParticipants from "./importOperationParticipants";
import ParticipantFormPanel from './participantFormPanel';
import {Loader} from '../../components/loader/loader';
import BackLinkWithoutText from "../../patterns/navigation/backLinkWithoutText";
import Panel, {PanelSize} from "../../patterns/panel/panel";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import InviteAllParticipants from "./listParticipants/actions/inviteAllParticipants";
import {sendEmailInvitationsToUninvitedParticipants} from "../../services/OperationService";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import {TOperationParticipant} from '../../interfaces/operationParticipant';
import Footer from "../../patterns/footer/footer";
import OperationTransactions from '../operationTransactions/operationTransactions';
import OperationCodes from '../operationCodes/operationCodes';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

export enum OperationParticipantsMenu {
  LIST,
  ATTRIBUTES,
  IMPORT,
  HISTORY,
  CODES
}

export default function OperationParticipants(): JSX.Element {
  const {operationId} = useParams<string>();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const [operation, setOperation] = useState(store.global.currentOperation);
  const extractSelectedMenu = (): OperationParticipantsMenu|null => {
    switch (location.pathname) {
    case operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operationId!):
      return OperationParticipantsMenu.LIST;
    case operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANT_ATTRIBUTES, operationId!):
      return OperationParticipantsMenu.ATTRIBUTES;
    case operationUrl(PRIVATE_URL.IMPORT_OPERATION_PARTICIPANTS, operationId!):
      return OperationParticipantsMenu.IMPORT;
    case operationUrl(PRIVATE_URL.OPERATION_POINTS_HISTORY, operationId!):
      return OperationParticipantsMenu.HISTORY;
    case operationUrl(PRIVATE_URL.LIST_OPERATION_CODES, operationId!):
      return OperationParticipantsMenu.CODES;
    default:
      navigate(PRIVATE_URL.LANDING_PAGE);
      return null;
    }
  }

  const setError = (error: string|null): void => { if (error) addErrorAlert(error, setAlerts) }
  const setSuccess = (success: string): void => addSuccessAlert(success, setAlerts)

  const [selectedMenu, setSelectedMenu] = useState(extractSelectedMenu());

  useEffect(() => {
    setSelectedMenu(extractSelectedMenu());
  }, [location]);

  useEffect(() => {
    setOperation(store.global.currentOperation);
  }, [store])

  const menus = [
    {
      icon: AssignmentIndIcon,
      label: 'operation_participants.menu.list',
      url: operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operationId!)
    },
    {
      icon: UploadFileIcon,
      label: 'operation_participants.menu.import',
      url: operationUrl(PRIVATE_URL.IMPORT_OPERATION_PARTICIPANTS, operationId!)
    },
    {
      icon: VpnKeyIcon,
      label: 'operation_participants.menu.codes',
      url: operationUrl(PRIVATE_URL.LIST_OPERATION_CODES, operationId!)
    },
    {
      icon: ReceiptLongIcon,
      label: 'operation_participants.menu.history',
      url: operationUrl(PRIVATE_URL.OPERATION_POINTS_HISTORY, operationId!)
    },
    {
      icon: AutorenewIcon,
      label: 'operation_participants.menu.attributes',
      url: operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANT_ATTRIBUTES, operationId!)
    }
  ]

  const [reload, setReload] = useState(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const sendMails = (sendAt: string): Promise<void> => {
    return sendEmailInvitationsToUninvitedParticipants(operationId!, sendAt)
      .then(response => {
        setReload(true);
        return response;
      })
      .then(response => {
        addSuccessAlert(t("operation_participants.action_invite_success", {count: response.sent.count}), setAlerts);
        closeInviteAllParticipants();
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), (error: string) => addErrorAlert(error, setAlerts), t);
      })
  }

  const [openInviteAllParticipants, setOpenInviteAllParticipants] = useState(false)
  const closeInviteAllParticipants = (): void => setOpenInviteAllParticipants(false)

  const [participantPanelOpened, setParticipantPanelOpen] = useState<boolean>(false);
  const [participantPanelUpdate, setParticipantPanelUpdate] = useState<TOperationParticipant|null>(null);
  const participantPanelTitle = (): string => {
    if (participantPanelUpdate && participantPanelUpdate.participant.firstName && participantPanelUpdate.participant.lastName) {
      return `<b>${participantPanelUpdate.participant.firstName} ${participantPanelUpdate.participant.lastName}</b>`;
    } else if (participantPanelUpdate) {
      return t("participants.update_participant_title");
    } else {
      return t("participants.create_participant_title");
    }
  }
  const openParticipantFormPanel = (participant: TOperationParticipant|null): void => {
    setParticipantPanelUpdate(participant);
    setParticipantPanelOpen(true);
  };
  const closeParticipantFormPanel = (): void => {
    setParticipantPanelUpdate(null);
    setParticipantPanelOpen(false);
  }

  const operationParticipantsHeader =
    <Box sx={{display: "flex"}}>
      {operation?.participantStats.total != 0 ? <><Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium", pr: 1}}>
        {t("operation_participants.count_total", {number: operation?.participantStats.total})}
      </Typography>
      <Typography variant="body2" color="neutral.main" sx={{pl: 1, borderLeft: "1px solid", borderColor: "neutral.light"}}>
        {t("operation_participants.count_connected", {number: operation?.participantStats.connected})}
      </Typography></> :
        <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium"}} dangerouslySetInnerHTML={{__html: t("operation_participants.participants_title")}}/>}
    </Box>

  const importsHeader = <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium"}}
    dangerouslySetInnerHTML={{__html: t("operation_participants.import_title")}}/>

  const historyHeader = <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium"}}
    dangerouslySetInnerHTML={{__html: t("operation_participants.history_title")}}/>

  const operationParticipantAttributesHeader =
    <Box sx={{display: "flex"}}>
      <Typography variant="body2" color="neutral.main" sx={{fontWeight: "regular", mr: 0, "span": {color: "neutral.dark", fontWeight: "medium"}}} dangerouslySetInnerHTML={{__html: t("operation_participants.attributes_title")}} />
    </Box>

  const codesHeader = <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium"}}
    dangerouslySetInnerHTML={{__html: t("operation_participants.codes_title")}}/>

  return operation == null ? <Loader /> :
    <Box sx={{minHeight: "100vh", backgroundColor: "ornament.main", display: "flex", flexDirection: "column", overflowY: "auto"}}>
      {openInviteAllParticipants && <InviteAllParticipants key="invite-all-participants" openDialog={openInviteAllParticipants} closeDialog={closeInviteAllParticipants} sendMail={sendMails} operation={operation} />}
      <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
      <Box sx={{display: "flex", flexDirection: "column", px: 7, backgroundColor: "ornament.light", boxShadow: 1, flex: "0 0 auto"}}>
        <Box sx={{display: "flex", alignItems: "center", pt: 5, pb: 6, justifyContent: "space-between"}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <BackLinkWithoutText to={operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, operation.id)}/>
            <Typography variant="h1" sx={{ml: 3, fontWeight: "bold", color: "neutral.dark"}}>
              {t("operation_participants.title")}
            </Typography>
          </Box>
        </Box>
        <ThirdLevelMenu sx={{mx: "0px"}} menus={menus}/>
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", height: "100%", mb: 5, mx: 7, flex: 1}}>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 6, mb: 5}}>
          {selectedMenu === OperationParticipantsMenu.LIST && operationParticipantsHeader}
          {selectedMenu === OperationParticipantsMenu.ATTRIBUTES && operationParticipantAttributesHeader}
          {selectedMenu === OperationParticipantsMenu.IMPORT && importsHeader}
          {selectedMenu === OperationParticipantsMenu.HISTORY && historyHeader}
          {selectedMenu === OperationParticipantsMenu.CODES && codesHeader}
        </Box>
        {selectedMenu === OperationParticipantsMenu.LIST && <ListOperationParticipants operation={operation} reload={reload} setReload={setReload} participantUpdate={openParticipantFormPanel}
          addSuccess={(success: string): void => addSuccessAlert(success, setAlerts)} addError={(error: string): void => addErrorAlert(error, setAlerts)}/>}
        {selectedMenu === OperationParticipantsMenu.ATTRIBUTES && <ListOperationParticipantAttributes addSuccess={(success: string): void => addSuccessAlert(success, setAlerts)} addError={(error: string): void => addErrorAlert(error, setAlerts)} reload={reload} setReload={setReload}/>}
        {selectedMenu === OperationParticipantsMenu.IMPORT && <ImportOperationParticipants/>}
        {selectedMenu === OperationParticipantsMenu.HISTORY && <OperationTransactions operation={operation} setError={setError} setSuccess={setSuccess}/>}
        {selectedMenu === OperationParticipantsMenu.CODES && <OperationCodes operation={operation} addSuccess={(success: string): void => addSuccessAlert(success, setAlerts)} addError={(error: string): void => addErrorAlert(error, setAlerts)} />}
        {participantPanelOpened && <Panel title={participantPanelTitle()} size={PanelSize.SMALL} open={participantPanelOpened} close={closeParticipantFormPanel}>
          <ParticipantFormPanel setClose={closeParticipantFormPanel} addSuccess={(success: string): void => addSuccessAlert(success, setAlerts)} addError={(error: string): void => addErrorAlert(error, setAlerts)} setReload={setReload} operationParticipant={participantPanelUpdate}/>
        </Panel>}
      </Box>
      <Footer sx={{flex: "0 0 auto"}}/>
    </Box>
}
