export enum QuickFilterType {
    LASTWEEK = 'LAST_WEEK',
    ACTUALMONTH = 'ACTUAL_MONTH',
    LASTMONTH = 'LAST_MONTH',
    LASTQUARTER = 'LAST_QUARTER',
    LASTYEAR = 'LAST_YEAR',
    CURRENTYEAR = 'CURRENT_YEAR',
    INACTIVECOMPANIES = 'INACTIVE_COMPANIES',
    USED_CODE = 'USED_CODE',
    UNUSED_CODE = 'UNUSED_CODE'
}
