import * as React from 'react';
import {createContext, useEffect, useState, FormEvent} from 'react';
import './App.scss';
import {BrowserRouter, Route, Routes, Link} from "react-router-dom";
import {PRIVATE_URL, PUBLIC_URL} from './common/struct/urlManager';
import ForgetPassword from './pages/forgetPassword/forgetPassword';
import Register from "./pages/register/register";
import SuperAdminHome from "./pages/superAdmin/superAdminHome";
import {initialStore, StoreContext} from "./common/struct/store";
import Error404 from "./pages/error404/error404";
import PrivateRoute from "./common/auth/privateRoute";
import PublicRoute from "./common/auth/publicRoute";
import {isAuthenticated, isSuperAdmin} from "./common/struct/globalVar";
import OperationCharacteristics from "./pages/operationCharacteristics/operationCharacteristics";
import ResetPassword from "./pages/resetPassword/resetPassword";
import SideBarMenu, {SideBarProperties, SideBarTypes} from './patterns/sideBar/sideBarMenu';
import {Alert, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, ThemeProvider, Typography, useMediaQuery} from '@mui/material';
import {LibertyTheme} from './tokens/libertyTheme';
import Login from "./pages/login/login";
import OperationShowcase from "./pages/operationShowcase/operationShowcase";
import {getAllOperations} from "./services/OperationService";
import UserSettings from './pages/userSettings/userSettings';
import LanguageSelector from "./patterns/language/languageSelector";
import GoogleAnalyticsTracker from "./patterns/googleAnalytics/googleAnalyticsTracker";
import OperationRules from "./pages/operationRules/operationRules";
import OperationMainPage from "./pages/operationMainPage/operationMainPage";
import {Loader} from './components/loader/loader';
import OperationShop from "./pages/operationShop/operationShop";
import OperationGiftsHistory from "./pages/operationGiftsHistory/operationGiftsHistory";
import Payment from "./pages/payment/payment";
import PaymentSuccess from './pages/payment/paymentSuccess';
import {changeInvitedAdminPassword, getAdmin} from "./services/CompanyService";
import {globalStoreReducer} from "./common/methods/context-setter/globalStoreReducer";
import {ADD_OPERATIONS, LOGIN, LOGOUT} from "./common/methods/context-setter/globals";
import CompanyBilling from './pages/companyBilling/companyBilling';
import Faq from "./pages/faq/Faq";
import CompanyHistory from './pages/companyHistory/companyHistory';
import EmailTemplates from './pages/emailTemplates/emailTemplates';
import EmailTemplateForm from "./pages/emailTemplates/emailTemplateForm";
import OperationPresentation from "./pages/operationPresentation/operationPresentation";
import OperationParticipants from "./pages/operationParticipants/operationParticipants";
import ValidateEmail from "./pages/validateEmail/validateEmail";
import {LicenseInfo} from '@mui/x-license-pro';
import CompanyAdministrators from './pages/companyAdministrators/companyAdministrators';
import OperationMailing from './pages/operationMailing/operationMailing';
import LandingPage from "./pages/landingPage/landingPage";
import CreateOperationWizard from './pages/createOperationWizard/createOperationWizard';
import ListSnackbar, {TAlert} from "./patterns/list/listSnackbar";
import {addErrorAlert, addSuccessAlert} from "./patterns/list/listUtils";
import ImportParticipantsWizard from './pages/importParticipantsWizard/importParticipantsWizard';
import {useTranslation} from "react-i18next";
import {CookieConsent, Cookies} from "react-cookie-consent";
import {saveCookieConsent} from "./services/AuthenticationService";
import PaymentConfirmation from './pages/payment/paymentConfirmation';
import {pdfjs} from "react-pdf";
import Maintenance from "./pages/maintenance/maintenance";
import LoggedInLandingPage from './pages/landingPage/loggedInLandingPage';
import {Helmet} from "react-helmet";
import Wallets from "./pages/wallets/wallets";
import ElementInputWithLabel from './patterns/form/elementInputWithLabel';
import {INPUT_TYPES} from './patterns/form/formConstants';
import {PrimaryBigButton} from './components/buttons/mainButton';
import {manageStringError} from './common/methods/ApiService';
import RegisterSideBar from './pages/register/registerSideBar';
import EmailValidationSent from './pages/validateEmail/emailValidationSent';

// @ts-ignore
export const AppContext = createContext<StoreContext>();

export default function App(): JSX.Element {
  const [state, setState] = useState(initialStore);
  const [loading, setLoading] = useState(true);
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [cookieAccepted, setCookieAccepted] = useState<boolean>(Cookies.get("joozyBoCookie") == "true");
  const [password, setPassword] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [errorReset, setErrorReset] = useState<string|null>(null);
  const [resetDialog, setResetDialog] = useState(true);
  const [legalChecked, setLegalChecked] = useState(false);
  const [showImage, setShowImage] = useState(window.innerHeight > 940);

  const {t} = useTranslation();

  const loadOperations = (): Promise<void> => {
    if (isSuperAdmin()) {
      return Promise.resolve();
    }
    return getAllOperations().then(operations => globalStoreReducer([state, setState], {type: ADD_OPERATIONS, operations: operations}))
  }

  const updateVisualElements = (): void => {
    setShowImage(window.innerHeight > 940);
  }

  const loadAdmin = (): Promise<void> => {
    return getAdmin().then(admin => {      
      admin?.passwordInitDate ? setResetDialog(false) : setResetDialog(true);
      globalStoreReducer([state, setState], {type: LOGIN, admin: admin??undefined})
    })
  }

  useEffect(() => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY!);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const handleWindowSizeChange = (): void => updateVisualElements();
    window.addEventListener('resize', handleWindowSizeChange);
    return (): any => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production") {
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    }
  }, [cookieAccepted]);

  useEffect(() => {
    setLoading(true);
    if (isAuthenticated()) {
      loadOperations()
        .then(() => loadAdmin())
        .then(() => {
          if (state.global.notification) addSuccessAlert(state.global.notification, setAlerts)
          if (state.global.errorNotification) addErrorAlert(state.global.errorNotification, setAlerts)
        })
        .catch(() => globalStoreReducer([state, setState], {type: LOGOUT}))
        .finally(() => setLoading(false))
    } else {
      setLoading(false);
    }
  }, [state.global.loggedIn]);

  useEffect(() => {
    if (isAuthenticated() && state.global.notification) {
      addSuccessAlert(state.global.notification, setAlerts);
    }
  }, [state.global.notification]);

  useEffect(() => {
    if (isAuthenticated() && state.global.errorNotification) {
      addErrorAlert(state.global.errorNotification, setAlerts);
    }
  }, [state.global.errorNotification]);

  const breakpoint = useMediaQuery('(min-width:1100px)');

  const privateRoute = (component: JSX.Element, sideBarType?: SideBarTypes): JSX.Element => {
    const sideBarProperties = SideBarProperties[sideBarType??SideBarTypes.GLOBAL];
    return (
      <PrivateRoute>
        {process.env.REACT_APP_ENVIRONMENT !== "development" && state.global.admin.emailConfirmationDate == undefined ?
          <Box sx={{width: "100%", height: "100%", display: "flex"}}>
            {breakpoint && <RegisterSideBar showImage={showImage} sx={{height: "100vh"}}/>}
            <EmailValidationSent />
          </Box>
          :
          <>
            <GoogleAnalyticsTracker/>
            <SideBarMenu type={sideBarType??SideBarTypes.GLOBAL}/>
            <Box sx={{width: "100%", height: "100%", marginLeft: sideBarProperties.width}}>
              {passwordDialog}
              {component}
            </Box>
          </>
        }
      </PrivateRoute>
    )
  }

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    changeInvitedAdminPassword(state.global.admin.id, password, legalChecked)
      .then(admin => {
        globalStoreReducer([state, setState], {type: LOGIN, admin: admin??undefined, notification: t("reset_password.success")});
        setResetDialog(false);
      })
      .catch(error => manageStringError(error, setErrorReset, t));
  }

  const passwordDialog = <Dialog open={resetDialog} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown fullWidth maxWidth="sm">
    <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mr: 8}}>
          {t("reset_password.title_invited")}
        </Typography>
      </Box>
    </DialogTitle>
    <DialogContent sx={{pb: "0px"}}>
      {errorReset && <Alert severity="error" variant="filled" sx={{width: "100%", boxSizing: "border-box", mb: 3}}>{errorReset}</Alert>}
      <ElementInputWithLabel disabled={loading} passwordCheck={true} type={INPUT_TYPES.PASSWORD} label={t("reset_password.form.password")} name="password" value={password} updateValue={setPassword} placeholder={t("reset_password.form.password_placeholder")} violations={[]} canSubmit={canSubmit} setCanSubmit={setCanSubmit} sx={{mb: 3}}/>
      <FormControlLabel
        name="made_ethics"
        sx={{m: "unset", mb: 5}}
        control={<Checkbox required checked={legalChecked} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={(): void => setLegalChecked(!legalChecked)}/>}
        label={<Typography variant="body1" color="neutral.dark" sx={{fontWeight: "medium", mx: 0, ml: 3, "a": {color: "primary.main"}}}>{t("register.legal.accept_label")}<Link to={PUBLIC_URL.CGVU} target={"_blank"} style={{fontWeight: 800, textDecoration: "none"}}>{t("register.legal.accept_label_link")}</Link></Typography>}
      />
    </DialogContent>
    <DialogActions sx={{p: 2, borderTop: "1px solid", borderColor: "ornament.dark"}}>
      <PrimaryBigButton sx={{mr: 0}} label={t("reset_password.button_invited")} disabled={!canSubmit || !legalChecked} action={handleSubmit}/>
    </DialogActions>
  </Dialog>

  const publicRoute = (component: JSX.Element): JSX.Element => {
    return (
      <PublicRoute>
        <Box sx={{width: "100%", height: "100%"}}>
          <GoogleAnalyticsTracker/>
          {component}
        </Box>
      </PublicRoute>
    )
  }

  function CookieFooter(): JSX.Element {
    const {t} = useTranslation();
    const handleAcceptCookie = (): void => {
      saveCookieConsent().then(() => setCookieAccepted(true))
    }

    return (
      <CookieConsent
        location="bottom"
        buttonText={t("cookie.button")}
        cookieName="joozyBoCookie"
        onAccept={handleAcceptCookie}
        style={{
          backgroundColor: LibertyTheme.palette.primaryDark.main,
          padding: `${LibertyTheme.spacing(1)} ${LibertyTheme.spacing(3)}`,
          boxSizing: "border-box"
        }}
        contentStyle={{
          margin: 0
        }}
        buttonStyle={{
          color: LibertyTheme.palette.primaryDark.main,
          backgroundColor: LibertyTheme.palette.ornament.light,
          borderRadius: 4,
          margin: 0
        }}
      >
        <Typography variant="body2" color="ornament.light">
          {t("cookie.content")}
        </Typography>
      </CookieConsent>
    )
  }

  return !loading ?
    <AppContext.Provider value={[state, setState]}>
      <ThemeProvider theme={LibertyTheme}>
        <Box sx={{display: "flex", height: "100%"}}>
          <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
          <LanguageSelector/>
          <CookieFooter/>
          <Helmet>
            <title>{t("global.meta_title")}</title>
            <meta name="description" content={t("global.meta_description")} />
          </Helmet>
          <BrowserRouter>
            <Routes>
              {/** CONNEXION */}
              <Route path={PUBLIC_URL.REGISTER} element={publicRoute(<Register/>)}/>
              <Route path={PUBLIC_URL.LOGIN} element={publicRoute(<Login/>)}/>
              <Route path={PUBLIC_URL.FORGET_PASSWORD} element={publicRoute(<ForgetPassword/>)}/>
              <Route path={PUBLIC_URL.RESET_PASSWORD} element={publicRoute(<ResetPassword/>)}/>
              <Route path={PUBLIC_URL.VALIDATE_EMAIL} element={publicRoute(<ValidateEmail/>)}/>

              {/** PARAMETRES */}
              <Route path={PRIVATE_URL.COMPANY_ADMINISTRATORS} element={privateRoute(<CompanyAdministrators/>)}/>
              <Route path={PRIVATE_URL.BILLING_ADDRESS} element={privateRoute(<CompanyBilling/>)}/>
              <Route path={PRIVATE_URL.BILLING_HISTORY} element={privateRoute(<CompanyBilling/>)}/>
              <Route path={PRIVATE_URL.COMPANY_HISTORY} element={privateRoute(<CompanyHistory/>)}/>
              <Route path={PRIVATE_URL.USER_SETTINGS} element={privateRoute(<UserSettings/>)}/>
              <Route path={PRIVATE_URL.FAQ} element={privateRoute(<Faq/>)}/>
              <Route path={PRIVATE_URL.WALLETS_BUY_POINTS} element={privateRoute(<Wallets/>)}/>
              <Route path={PRIVATE_URL.WALLETS_ONGOING} element={privateRoute(<Wallets/>)}/>
              <Route path={PRIVATE_URL.WALLETS_LIST} element={privateRoute(<Wallets/>)}/>
              <Route path={PRIVATE_URL.WALLETS_POINTS_MOVEMENTS} element={privateRoute(<Wallets/>)}/>

              {/** SUPER ADMIN */}
              <Route path={PRIVATE_URL.ADD_EMAIL_TEMPLATE} element={privateRoute(<EmailTemplateForm/>)}/>
              <Route path={PRIVATE_URL.UPDATE_EMAIL_TEMPLATE} element={privateRoute(<EmailTemplateForm/>)}/>
              <Route path={PRIVATE_URL.EMAIL_TEMPLATES} element={privateRoute(<EmailTemplates/>)}/>
              <Route path={PRIVATE_URL.SUPER_ADMIN_HOME} element={privateRoute(<SuperAdminHome/>)}/>
              <Route path={PRIVATE_URL.SUPER_ADMIN_COMPANIES} element={privateRoute(<SuperAdminHome/>)}/>
              <Route path={PRIVATE_URL.SUPER_ADMIN_ADMINS} element={privateRoute(<SuperAdminHome/>)}/>
              <Route path={PRIVATE_URL.SUPER_ADMIN_OPERATIONS} element={privateRoute(<SuperAdminHome/>)}/>

              {/** OPERATIONS */}
              <Route path={PRIVATE_URL.OPERATION_DASHBOARD} element={privateRoute(<OperationMainPage/>)}/>
              <Route path={PRIVATE_URL.OPERATION_CUSTOMISATION} element={privateRoute(<OperationMainPage/>)}/>
              <Route path={PRIVATE_URL.OPERATION_GIFTS_HISTORY} element={privateRoute(<OperationGiftsHistory/>)}/>
              <Route path={PRIVATE_URL.OPERATION_RULES} element={privateRoute(<OperationRules/>)}/>
              <Route path={PRIVATE_URL.OPERATION_PRESENTATION} element={privateRoute(<OperationPresentation/>)}/>
              <Route path={PRIVATE_URL.OPERATION_CHARACTERISTICS} element={privateRoute(<OperationCharacteristics/>)}/>
              <Route path={PRIVATE_URL.OPERATION_SHOWCASE} element={privateRoute(<OperationShowcase/>)}/>
              <Route path={PRIVATE_URL.OPERATION_SHOP_PRICE} element={privateRoute(<OperationShop/>)}/>
              <Route path={PRIVATE_URL.OPERATION_SHOP_CRITERIA} element={privateRoute(<OperationShop/>)}/>
              <Route path={PRIVATE_URL.OPERATION_SHOP_UNIVERSES} element={privateRoute(<OperationShop/>)}/>
              <Route path={PRIVATE_URL.OPERATION_SHOP_GIFTS} element={privateRoute(<OperationShop/>)}/>
              <Route path={PRIVATE_URL.OPERATION_SHOP_SETTINGS} element={privateRoute(<OperationShop/>)}/>
              <Route path={PRIVATE_URL.OPERATION_EMAIL_SETTINGS} element={privateRoute(<OperationMailing/>)}/>
              <Route path={PRIVATE_URL.OPERATION_EMAIL_CUSTOMISATION} element={privateRoute(<OperationMailing/>)}/>
              <Route path={PRIVATE_URL.OPERATION_EMAIL_HISTORY} element={privateRoute(<OperationMailing/>)}/>
              <Route path={PRIVATE_URL.OPERATION_EMAIL_PLANNED} element={privateRoute(<OperationMailing/>)}/>
              <Route path={PRIVATE_URL.TRANSACTION_PAYMENT} element={privateRoute(<Payment/>)}/>
              <Route path={PRIVATE_URL.TRANSACTION_PAYMENT_CONFIRM} element={privateRoute(<PaymentConfirmation/>)}/>
              <Route path={PRIVATE_URL.TRANSACTION_PAYMENT_SUCCESS} element={privateRoute(<PaymentSuccess/>)}/>

              {/** PARTICIPANTS */}
              <Route path={PRIVATE_URL.LIST_OPERATION_PARTICIPANTS} element={privateRoute(<OperationParticipants/>)}/>
              <Route path={PRIVATE_URL.LIST_OPERATION_PARTICIPANT_ATTRIBUTES} element={privateRoute(<OperationParticipants/>)}/>
              <Route path={PRIVATE_URL.IMPORT_OPERATION_PARTICIPANTS} element={privateRoute(<OperationParticipants/>)}/>
              <Route path={PRIVATE_URL.OPERATION_POINTS_HISTORY} element={privateRoute(<OperationParticipants/>)}/>
              <Route path={PRIVATE_URL.LIST_OPERATION_CODES} element={privateRoute(<OperationParticipants/>)}/>

              {/** WIZARD */}
              <Route path={PRIVATE_URL.IMPORT_PARTICIPANTS_WIZARD} element={privateRoute(<ImportParticipantsWizard/>, SideBarTypes.NONE)}/>
              <Route path={PRIVATE_URL.CREATE_OPERATION_WIZARD} element={privateRoute(<CreateOperationWizard/>, SideBarTypes.NONE)}/>

              {/** GLOBAL */}
              <Route path={PRIVATE_URL.WELCOME} element={privateRoute(<LoggedInLandingPage/>)}/>
              <Route path={PUBLIC_URL.MAINTENANCE_MODE_ON} element={<Maintenance/>}/>
              <Route path="/" element={publicRoute(<LandingPage/>)}/>
              <Route path="*" element={<Error404/>}/>
            </Routes>
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </AppContext.Provider> : <Loader />
}
