import React, {useContext, useEffect, useState} from 'react';
import {RequestFilter, RequestOrder} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import {TWalletTransaction} from "../../interfaces/wallet";
import List from "../../patterns/list/list";
import {GridEnrichedColDef, GridValueGetterParams, GridFilterModel, useGridApiRef, GridFilterListIcon} from "@mui/x-data-grid-pro";
import {columnDefinition, ColumnType, enumFilterWithoutNull, valueGetter} from "../../patterns/list/listUtils";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {getOperationRewards} from "../../services/RewardService";
import emptyTransactions from "../../assets/lists/empty_transactions.svg";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import ListMenuItem from '../../patterns/list/listMenuItem';
import {getExportParticipantsRewards} from '../../services/ImportService';
import {getExportDocument} from "../../common/methods/exportDocument";
import {TAlert} from "../../patterns/list/listSnackbar";
import {TOperation} from '../../interfaces/operation';
import {categoryColumnDefinition} from '../operationParticipants/listParticipants/transactions/listParticipantTransactions';
import {EuroPointToggle, PointsUnit} from '../../patterns/list/euroPointToggle';
import {Box, Menu} from '@mui/material';
import {PrimarySmallButton} from '../../components/buttons/mainButton';
import {QuickFilterType} from "../../interfaces/quickFilter";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import moment from "moment";
import {IconPosition} from "../../components/buttons/buttonProps";

export type PointsCardProps = {
  operation: TOperation,
  setError: (error: string) => void,
  setSuccess: (success: string) => void
  setOngoingTransactions?: (ongoingTransactions: boolean) => void
}

export default function OperationTransactions(props: PointsCardProps): JSX.Element {
  const {operation} = props;
  const {t} = useTranslation();  
  const apiRef = useGridApiRef();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState(PointsUnit.POINTS);

  const [quickFilters, setQuickFilters] = useState<GridFilterModel>({items: []});
  const [quickFilterSelected, setQuickFilterSelected] = useState<QuickFilterType|null>(null);

  const [actionsAnchorEl, setActionsAnchorEl] = useState<undefined|HTMLElement>(undefined);

  const handleActionsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = (): void => {
    setActionsAnchorEl(undefined);
  };

  useEffect(() => {
    document.title = t("tab_title.operation.points.history");
  }, []);

  const loadTransactions = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TWalletTransaction[]}> => {
    return getOperationRewards(operation.id, page, maxItems, search, order, filters);
  }

  const exportParticipants = (): Promise<void> => {
    return getExportParticipantsRewards(operation.id!)
      .then(response => {
        getExportDocument(response)
      });
  }

  const exportAction = <ListMenuItem key={Math.random()} label={t("operation_participants.actions.export")} icon={FileDownloadIcon} action={(): any => exportParticipants()}/>
  const euroToPointAction = <Box key={Math.random()} sx={{mr: 1}}><EuroPointToggle selected={selectedUnit} setSelected={setSelectedUnit}/></Box>

  const operationParticipantNameColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "rewards", "operationParticipant.name", 150, ColumnType.STRING, true),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.operationParticipant ? item.row.operationParticipant.participant['name'] : "-", ColumnType.STRING)
    }
  }

  const walletColumnDefinition = (): GridEnrichedColDef => {    
    return {
      ...columnDefinition(t, "rewards", "wallet.name", 150, ColumnType.STRING, true),
      filterOperators: enumFilterWithoutNull,
      valueOptions: store.global.company.wallets.map(wallet => ({value: wallet.name, label: wallet.id != store.global.company.defaultWallet!.id && wallet.id != store.global.company.expiredPointsWallet!.id ? wallet.name : wallet.id == store.global.company.defaultWallet!.id && wallet.name == 'default' ? "Budget par défaut" : "Budget points expirés"})),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.wallet ? (item.row.wallet['name'] == store.global.company.defaultWallet!.name ? t("wallets.config.default_wallet.name") : item.row.wallet['name'] == store.global.company.expiredPointsWallet!.name ? t("wallets.config.expired_points_wallet.name") : item.row.wallet['name']) : "-", ColumnType.STRING)
    }
  }

  const columns = [
    categoryColumnDefinition(t),
    columnDefinition(t, "rewards", selectedUnit == PointsUnit.POINTS ? "pointAmount" : "amount", 150, selectedUnit == PointsUnit.POINTS ? ColumnType.POINTS : ColumnType.EUROS),
    columnDefinition(t, "rewards", "reason", 500),
    columnDefinition(t, "rewards", "createdAt", 250, ColumnType.DATE_TIME),
    operationParticipantNameColumnDefinition(),
    walletColumnDefinition()
  ]

  const updateFilters = (dateStart: string, dateEnd: string, filterSelected: QuickFilterType): void => {
    if (filterSelected === quickFilterSelected) {
      setQuickFilters({items: []});
      setQuickFilterSelected(null);
    } else {
      setQuickFilters({items: [{columnField: "createdAt", operatorValue: "after", value: dateStart}, {columnField: "createdAt", operatorValue: "before", value: dateEnd}]})
      setQuickFilterSelected(filterSelected);
    }
  }

  const quickFilterAction = <Box>
    <PrimarySmallButton label={t("operation_gifts_history.actions.quick_filter")} action={handleActionsClick}
      icon={GridFilterListIcon} position={IconPosition.LEFT} sx={{mr: 1}}/>
    <Menu id="actions-menu" open={actionsAnchorEl != null} onClose={handleActionsClose} anchorEl={actionsAnchorEl}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}} transformOrigin={{vertical: "top", horizontal: "right"}}
      sx={{"& .MuiPaper-root": {mt: 0, border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3}}}>
      <ListMenuItem label={t("operation_participants.actions.quick.last_week")} icon={quickFilterSelected === QuickFilterType.LASTWEEK ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'), QuickFilterType.LASTWEEK) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.actual_month")} icon={quickFilterSelected === QuickFilterType.ACTUALMONTH ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf("month").format('YYYY-MM-DD'), QuickFilterType.ACTUALMONTH) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_month")} icon={quickFilterSelected === QuickFilterType.LASTMONTH ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), QuickFilterType.LASTMONTH) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_quarter")} icon={quickFilterSelected === QuickFilterType.LASTQUARTER ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'Q').startOf('Q').format('YYYY-MM-DD'), moment().subtract(1, 'Q').endOf('Q').format('YYYY-MM-DD'), QuickFilterType.LASTQUARTER) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_year")} icon={quickFilterSelected === QuickFilterType.LASTYEAR ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'), QuickFilterType.LASTYEAR) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.actual_year")} icon={quickFilterSelected === QuickFilterType.CURRENTYEAR ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().startOf('year').format('YYYY-MM-DD'), moment().add(1, 'day').startOf("day").format('YYYY-MM-DD'), QuickFilterType.CURRENTYEAR) }}/>
    </Menu>
  </Box>

  return (
    <List
      translationPrefix="rewards"
      columns={columns}
      defaultOrder={{field: 'createdAt', sort: 'desc'}}
      apiRef={apiRef}
      loadRows={loadTransactions}
      height="100%"
      emptyListIcon={emptyTransactions}
      globalActions={[euroToPointAction, quickFilterAction]}
      listActions={[exportAction]}
      reload={reload}
      setReload={setReload}
      alerts={alerts}
      setAlerts={setAlerts}
      filters={quickFilters}
      sx={{width: "100%", mb: 3}}
      enableSelection={false}
    />
  )
}
