import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Chip, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useGridApiRef} from "@mui/x-data-grid-pro";
import emptyParticipants from "../../../assets/lists/empty_participants.svg";
import {manageStringError, RequestFilter, RequestOrder} from "../../../common/methods/ApiService";
import {IconPosition} from "../../../components/buttons/buttonProps";
import {PrimarySmallButton} from "../../../components/buttons/mainButton";
import {TParticipantAttribute, TParticipantAttributeType} from "../../../interfaces/participantAttribute";
import List from "../../../patterns/list/list";
import {
  actionColumn,
  columnDefinition,
  ColumnType,
  enumFilterWithoutNull,
  gridAction
} from "../../../patterns/list/listUtils";
import Panel, {PanelSize} from "../../../patterns/panel/panel";
import {deleteParticipantAttribute, getPaginatedParticipantAttributes} from "../../../services/ParticipantService";
import ParticipantAttributeForm from "./participantAttributeForm";
import DeleteDialog from "../../../patterns/dialog/deleteDialog";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";

interface ListOperationParticipantAttributeProps {
  addSuccess: (success: string) => void;
  addError: (error: string) => void;
  reload: boolean;
  setReload: (reload: boolean) => void;
}

export default function ListOperationParticipantAttributes(props: ListOperationParticipantAttributeProps): JSX.Element {
  const {addSuccess, addError, reload, setReload} = props;
  const {t} = useTranslation();
  const apiRef = useGridApiRef();
  useEffect(() => {
    document.title = t("tab_title.operation.participant.attribute");
  }, []);
  const [participantAttribute, setParticipantAttribute] = useState<TParticipantAttribute|null>(null);
  const [openAttributePanel, setOpenAttributePanel] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const createdByColumnDefinition: GridEnrichedColDef = {
    ...columnDefinition(t, "operation_participants.attributes", "createdBy", 170, ColumnType.STRING, false),
    valueGetter: (item): string => `${item.row.admin.firstName} ${item.row.admin.lastName}`
  }
  const typeColumnDefinition: GridEnrichedColDef = {
    ...columnDefinition(t, "operation_participants.attributes", "type", 170),
    filterOperators: enumFilterWithoutNull,
    valueOptions: Object.values(TParticipantAttributeType).map(status => ({value: status, label: t(`operation_participants.attributes.type.${status.toLowerCase()}`)})),
    valueGetter: (item): string => t("operation_participants.attributes.type." + item.row.type),
    renderCell: (item): JSX.Element => <Chip sx={{backgroundColor: "primaryLight.main", borderRadius: '4px'}}
      label={<Typography variant="body2" color="primary.main" sx={{fontWeight: "bold"}}>
        {item.value}
      </Typography>}/>
  }

  const listColumns = [
    columnDefinition(t, "operation_participants.attributes", "label", 170, ColumnType.STRING),
    columnDefinition(t, "operation_participants.attributes", "description", 170, ColumnType.STRING, true, true),
    createdByColumnDefinition,
    typeColumnDefinition,
    columnDefinition(t, "operation_participants.attributes", "createdAt", 170, ColumnType.DATE),
    actionColumn(params => [
      gridAction(params, "update", t(`operation_participants.attributes.actions.update`), EditIcon, (attribute: TParticipantAttribute): void => {
        setParticipantAttribute(attribute);
        setOpenAttributePanel(true);
      }),
      gridAction(params, "delete", t(`operation_participants.attributes.actions.delete`), DeleteIcon, (attribute: TParticipantAttribute): void => {
        setParticipantAttribute(attribute);
        setOpenDeleteDialog(true);
      })
    ])
  ]

  const loadAttributes = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TParticipantAttribute[]}> => {
    return getPaginatedParticipantAttributes(page, maxItems, search, order, filters);
  }

  const openPanel = (): void => {
    setParticipantAttribute(null);
    setOpenAttributePanel(true);
  }

  const deleteAttribute = (): Promise<void> => {
    if (participantAttribute == null) {
      return Promise.resolve();
    }

    return deleteParticipantAttribute(participantAttribute.id)
      .then(() => {
        setReload(true);
        addSuccess(t("operation_participants.attributes.actions.delete_success"));
      })
      .catch(error => manageStringError(error, addError, t))
      .finally(() => setOpenDeleteDialog(false))
  }

  const createAction = <PrimarySmallButton key="create-template" label={t("operation_participants.attributes.actions.create")} icon={AddIcon} sx={{mr: 1}}
    position={IconPosition.LEFT} action={openPanel}/>

  const attributePanelTitle = participantAttribute ? t("operation_participants.attributes.title_update") : t("operation_participants.attributes.title_create");

  const attributePanel = openAttributePanel ? <Panel key="attribute-panel-template" title={attributePanelTitle} size={PanelSize.SMALL} open={openAttributePanel} close={(): void => setOpenAttributePanel(false)}>
    <ParticipantAttributeForm addSuccess={addSuccess} setClose={(): void => setOpenAttributePanel(false)} setReload={setReload} attribute={participantAttribute}/>
  </Panel> : <></>

  const deleteAttributeDialog = <DeleteDialog
    key="delete-attribute"
    title={t("operation_participants.attributes.actions.delete_title", {"label": participantAttribute?.label})}
    open={openDeleteDialog}
    close={(): void => setOpenDeleteDialog(false)}
    warning={t("operation_participants.attributes.actions.delete_warning")}
    deleteElement={deleteAttribute}/>

  return <List
    sx={{gridColumn: "1/5"}}
    translationPrefix="operation_participants.attributes"
    columns={listColumns}
    apiRef={apiRef}
    loadRows={loadAttributes}
    reload={reload}
    setReload={setReload}
    emptyListIcon={emptyParticipants}
    height="70vh"
    globalActions={[createAction]}
    panels={[attributePanel]}
    dialogs={[deleteAttributeDialog]}
  />
}
