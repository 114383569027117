import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Alert, Box, Card, Typography} from "@mui/material";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {useTranslation} from "react-i18next";
import BackLink from "../../patterns/navigation/backLink";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import ElementInput from "../../patterns/form/elementInput";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {initWalletTransactionPaymentForTransfer} from "../../services/OperationService";
import {paymentStoreReducer} from "../../common/methods/context-setter/paymentStoreReducer";
import {INIT_PAYMENT} from "../../common/methods/context-setter/globals";
import {TWalletTransactionOption} from "../../interfaces/wallet";
import Payment from "./payment";
import BankDetails from "./bankDetails";
import {manageStringError} from "../../common/methods/ApiService";

export default function PaymentConfirmation(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const address = store.global.admin.invoiceAddress;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  const [reference, setReference] = useState(store.payment?.reference??"");
  const [referenceHasChanged, setReferenceHasChanged] = useState<boolean>(false);
  const [isTransfer, setIsTransfer] = useState<boolean|null>(null);

  useEffect(() => {
    if (!store.payment || !address) {
      navigate(PRIVATE_URL.WALLETS_BUY_POINTS);
    } else {
      setIsTransfer(store.payment.type == TWalletTransactionOption.TRANSFER);
    }
  }, [])

  const updateReference = (reference: string): void => {
    setReference(reference);
    setReferenceHasChanged(true);
  }

  const confirmTransfer = (): void => {    
    if (store.payment) {
      setLoading(true);
      initWalletTransactionPaymentForTransfer(store.payment.amount, (reference.length > 0 && referenceHasChanged) ? reference : null, store.payment.walletId)
        .then((content) => {          
          paymentStoreReducer(STORE, {type: INIT_PAYMENT, payment: {
            type: TWalletTransactionOption.TRANSFER,
            transactionId: content.id,
            amount: store.payment!.amount,
            walletId: store.payment!.walletId
          }});
          navigate(PRIVATE_URL.WALLETS_ONGOING);
        })
        .catch(error => {
          manageStringError(error, setError, t);
          setLoading(false);
        });
    }
  }

  return store.payment == null || address == null || isTransfer == null ? <></> :
    <Box sx={{height: "100vh"}}>
      <BackLink to={PRIVATE_URL.WALLETS_BUY_POINTS} label="back_to_payment"/>
      <Box sx={{display: "flex", height: "calc(100% - 80px)"}}>
        <Box sx={{width: "50%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Card sx={{boxShadow: 2, mx: 'auto', width: "80%"}}>
            <Typography variant="h1" color="neutral.dark" sx={{p: 6, fontWeight: "bold"}}>
              {t("payment.confirm.title")}
            </Typography>
            <Box sx={{display: "flex", flexDirection: "column", px: 6}}>
              <Typography variant="body1" color="neutral.dark" sx={{mb: 2, fontWeight: "bold"}}>{t("payment.confirm.amount")}</Typography>
              <Typography variant="body2" color="neutral.dark">{store.payment.amount} €</Typography>
              <Typography variant="body1" color="neutral.dark" sx={{mb: 2, mt: 3, fontWeight: "bold"}}>{t("payment.confirm.address")}</Typography>
              <Box>
                <Typography display="inline" variant="body2" color="neutral.dark" sx={{fontWeight: "medium", mr: 0}}>
                  {address.firstName}
                </Typography>
                <Typography display="inline" variant="body2" color="neutral.dark" sx={{fontWeight: "medium"}}>
                  {address.lastName}
                </Typography>
              </Box>
              <Typography variant="body2" color="neutral.dark" sx={{mt: 1}}>{address.society}</Typography>
              <Typography variant="body2" color="neutral.dark">{address.street}</Typography>
              {address.street2 && <Typography variant="body2" color="neutral.dark">{address.street2}</Typography>}
              {address.street3 && <Typography variant="body2" color="neutral.dark">{address.street3}</Typography>}
              <>
                <Typography display="inline" variant="body2" color="neutral.dark" sx={{mr: 0}}>{address.zip}</Typography>
                <Typography display="inline" variant="body2" color="neutral.dark">{address.city}</Typography>
              </>
              {isTransfer &&
                <>
                  <Typography variant="body1" color="neutral.dark" sx={{mb: 2, mt: 3, fontWeight: "bold"}}>{t("payment.confirm.intern_number")}</Typography>
                  <ElementInput name="reference" type={INPUT_TYPES.DEFAULT} value={reference} updateValue={updateReference}
                    disabled={loading} placeholder={t("payment.confirm.intern_number_placeholder")}/>
                </>
              }
              {reference && !isTransfer &&
                <>
                  <Typography variant="body1" color="neutral.dark" sx={{mb: 2, mt: 3, fontWeight: "bold"}}>{"Référence"}</Typography>
                  <Typography variant="body2" color="neutral.dark">{reference}</Typography>
                </>
              }
              {error && <Alert variant="filled" severity="error" sx={{mt: 3}}>{error}</Alert>}
            </Box>
            <Box sx={{py: 3, px: 6, mt: 3, borderTop: "1px solid", borderColor: "ornament.dark", textAlign: "end"}}>
              <SecondaryBigButton label={t("payment.confirm.button_label_address")} action={(): void => navigate(PRIVATE_URL.BILLING_ADDRESS)} />
              {isTransfer && <PrimaryBigButton label={t("payment.confirm.button_label")} action={confirmTransfer} loading={loading} sx={{ml: 2}}/>}
            </Box>
          </Card>
        </Box>
        <Box sx={{width: "50%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          {isTransfer ? <BankDetails /> : <Payment />}
        </Box>
      </Box>
    </Box>
}
