import {multiDel, getBlob, getPaginated, post, put, get, RequestFilter, RequestOrder} from "../common/methods/ApiService";
import {API_URL, GLOBAL_OPERATION_ID} from "../common/struct/urlManager";
import {TAccessCode, TAccessCodeCreate} from "../interfaces/accessCode";

export async function getOperationCodes(operationId: string, page: number, itemsPerPage: number, search: string|null, order: RequestOrder|null, filters: RequestFilter[]|null): Promise<{totalItems: number, items: TAccessCode[]}> {
  return getPaginated(`${API_URL.OPERATION}/${operationId}/access-codes`, page, itemsPerPage, search, order, filters);
}

export async function getAllOperationCodes(operationId: string, search: string|null, order: RequestOrder|null, filters: RequestFilter[]|null): Promise<TAccessCode[]> {
  return get(`${API_URL.OPERATION}/${operationId}/access-codes`, search, order, filters);
}

export async function getOperationCodesDeleted(operationId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TAccessCode[]}> {
  return getPaginated(API_URL.ACCESS_CODE_DELETED.replace(":" + GLOBAL_OPERATION_ID, operationId), page, itemsPerPage, search, order, filters);
}

export async function getAllOperationCodesDeleted(operationId: string, search: string, order: RequestOrder|null, filters: RequestFilter[]): Promise<TAccessCode[]> {
  return get(API_URL.ACCESS_CODE_DELETED.replace(":" + GLOBAL_OPERATION_ID, operationId), search, order, filters);
}

export async function getExportOperationCodes(operationId: string): Promise<Blob> {
  return getBlob(API_URL.EXPORT_OPERATION_CODES.replace(":" + GLOBAL_OPERATION_ID, operationId));
}

export async function deleteCode(operationId: string, filters?: RequestFilter[]|null): Promise<void> {
  return multiDel(`${API_URL.OPERATION}/${operationId}/access-codes`, null, filters);
}

export async function lockCode(operationId: string, filters?: RequestFilter[]|null): Promise<void> {
  return post(`${API_URL.OPERATION}/${operationId}/access-codes-lock`, null, null, filters);
}

export async function restoreOperationCodes(operationId: string, filters?: RequestFilter[]|null): Promise<TAccessCode> {
  return post(`${API_URL.OPERATION}/${operationId}/access-codes-restore`, null, null, filters);
}

export async function createAccessCode(operationId: string, accessCode: TAccessCodeCreate): Promise<TAccessCode> {
  return post(API_URL.ACCESS_CODE_CREATE.replace(":" + GLOBAL_OPERATION_ID, operationId), accessCode);
}

export async function updateAccessCode(accessCodeId: string, accessCode: TAccessCodeCreate): Promise<TAccessCode> {
  return put(API_URL.ACCESS_CODES, accessCodeId, accessCode);
}