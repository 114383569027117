import {API_URL} from "../common/struct/urlManager";
import {
  get,
  getPaginated,
  post,
  postWithoutToken,
  put,
  RequestFilter,
  RequestOrder
} from "../common/methods/ApiService";
import jwt from "jwt-decode";
import {Token} from "./AuthenticationService";
import {getAdminId} from "../common/struct/globalVar";
import {TCompany} from "../interfaces/company";
import {TAdmin, TAdminRegister} from "../interfaces/admin";
import {TWalletTransaction} from "../interfaces/wallet";

export async function getAllCompanies(page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TCompany[]}> {
  return getPaginated(`${API_URL.COMPANY}`, page, itemsPerPage, search, order, filters);
}

export async function getCompanies(): Promise<TCompany[]> {
  return get(`${API_URL.COMPANY}`);
}

export async function getAllCompaniesNoFilter(): Promise<{totalItems: number, items: TCompany[]}> {
  return get(API_URL.COMPANY);
}

export async function getAdmin(): Promise<TAdmin|null> {
  return get(`${API_URL.ADMIN}/${getAdminId()}`);
}

export async function getCompany(companyId: string): Promise<TCompany|null> {
  return get(`${API_URL.COMPANY}/${companyId}`);
}

export async function getCompanyWalletTransaction(companyId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TWalletTransaction[]}> {
  return getPaginated(`${API_URL.COMPANY}/${companyId}/wallettransactions`, page, itemsPerPage, search, order, filters);
}

export async function registerAdmin(admin: TAdminRegister): Promise<TAdmin> {
  return postWithoutToken(API_URL.REGISTER, admin).then(admin => {
    const authJSON = JSON.stringify(admin.token);
    const jwtToken = jwt<Token>(admin.token);

    localStorage.setItem('auth', authJSON);
    localStorage.setItem('id', jwtToken.username);
    return admin;
  });
}

export async function inviteAdmin(admin: TAdminRegister): Promise<TAdmin> {
  return post(API_URL.INVITE, admin);
}

export async function changeAdminPassword(adminId: string, password: string): Promise<TAdmin> {
  return put(API_URL.ADMIN, adminId, {password: password}).then(admin => {
    const authJSON = JSON.stringify(admin.token);
    const jwtToken = jwt<Token>(admin.token);

    localStorage.setItem('auth', authJSON);
    localStorage.setItem('id', jwtToken.username);
    return admin;
  });
}

export async function changeInvitedAdminPassword(adminId: string, password: string, legalChecked: boolean): Promise<TAdmin> {
  return put(API_URL.ADMIN, adminId, {password: password, cgvuDate: legalChecked ? new Date() : null}).then(admin => {
    const authJSON = JSON.stringify(admin.token);
    const jwtToken = jwt<Token>(admin.token);

    localStorage.setItem('auth', authJSON);
    localStorage.setItem('id', jwtToken.username);
    return admin;
  });
}

export async function changeEuroPerPoint(companyId: string, euroPerPoint: string): Promise<TCompany> {
  return put(API_URL.COMPANY, companyId, {euroPerPoint: euroPerPoint});
}
