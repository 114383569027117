import React, {useContext, useEffect, useState} from "react";
import {Card, CircularProgress, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {CompanyBillingAddressProps} from "./companyBillingProps";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import {errorManager, Violation} from "../../common/methods/ApiService";
import {updateAdminInvoiceAddress} from "../../services/AdminService";
import {TAddressType} from "../../interfaces/address";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {LOGIN} from "../../common/methods/context-setter/globals";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import PageForm from "../../patterns/page/pageForm";
import Footer from "../../patterns/footer/footer";
import {useNavigate} from "react-router-dom";

export default function CompanyBillingAddress(props: CompanyBillingAddressProps): JSX.Element {
  const {t} = useTranslation();
  const {setAlerts} = props;
  useEffect(() => {
    document.title = t("tab_title.companyBilling");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const [loading, setLoading] = useState<boolean>(false);

  const admin = store.global.admin;
  const company = store.global.company;
  const [lastName, setLastName] = useState(admin.invoiceAddress?.lastName??admin.lastName);
  const [firstName, setFirstName] = useState(admin.invoiceAddress?.firstName??admin.firstName);
  const [society, setSociety] = useState(admin.invoiceAddress?.society??company.name);
  const [street, setStreet] = useState(admin.invoiceAddress?.street??"");
  const [street2, setStreet2] = useState(admin.invoiceAddress?.street2??"");
  const [street3, setStreet3] = useState(admin.invoiceAddress?.street3??"");
  const [zip, setZip] = useState(admin.invoiceAddress?.zip??"");
  const [city, setCity] = useState(admin.invoiceAddress?.city??"");
  const [vat, setVat] = useState(admin.invoiceAddress?.vat??"");
  const [phone, setPhone] = useState(admin.invoiceAddress?.phone??admin.phone);
  const [violations, setViolations] = useState<Violation[]>([]);
  const navigate = useNavigate();

  const submit = (): void => {
    const address = {
      ...admin.invoiceAddress,
      type: TAddressType.INVOICE_ADDRESS,
      lastName,
      firstName,
      society,
      street,
      street2,
      street3,
      zip,
      city,
      vat,
      phone
    }

    setViolations([]);
    setLoading(true);
    updateAdminInvoiceAddress(admin.id, address)
      .then(admin => {
        globalStoreReducer(STORE, {type: LOGIN, admin: admin});
        addSuccessAlert(t("admin.update_success"), setAlerts);
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') addErrorAlert(errorResult, setAlerts);
        else setViolations(errorResult);
      })
      .finally(() => setLoading(false))
  }

  const loadingButton = <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}><Box sx={{mr: 3}}>{t("admin.invoice_address.vat_button")}</Box><CircularProgress size="20px" sx={{color: "ornament.main"}}/></Box>

  return (
    <PageForm submit={submit} loading={loading} loadingText={admin.invoiceAddress?.vat != vat ? loadingButton : undefined}>
      <Card sx={{display: "flex", flexDirection: "column", backgroundColor: "ornament.light", width: "566px", boxSizing: "border-box", m: 5, p: 5, boxShadow: 1}}>
        <ElementInputWithLabel
          name="invoiceAddress.society"
          label={t("admin.invoice_address.society")}
          placeholder={company.name}
          violations={violations}
          value={society}
          updateValue={setSociety}
          required={true}/>
        <ElementInputWithLabel
          name="invoiceAddress.lastName"
          label={t("admin.invoice_address.last_name")}
          placeholder={admin.lastName}
          violations={violations}
          value={lastName}
          updateValue={setLastName}/>
        <ElementInputWithLabel
          name="invoiceAddress.firstName"
          label={t("admin.invoice_address.first_name")}
          placeholder={admin.firstName}
          violations={violations}
          value={firstName}
          updateValue={setFirstName}/>
        <ElementInputWithLabel
          name="invoiceAddress.street"
          label={t("admin.invoice_address.street")}
          placeholder={t("admin.invoice_address.street_helper")}
          violations={violations}
          value={street}
          updateValue={setStreet}
          required={true}/>
        <ElementInputWithLabel
          name="invoiceAddress.street2"
          label={t("admin.invoice_address.street2")}
          placeholder={t("admin.invoice_address.street2_helper")}
          violations={violations}
          value={street2}
          updateValue={setStreet2}/>
        <ElementInputWithLabel
          name="invoiceAddress.street3"
          label={t("admin.invoice_address.street3")}
          placeholder={t("admin.invoice_address.street3_helper")}
          violations={violations}
          value={street3}
          updateValue={setStreet3}/>
        <ElementInputWithLabel
          name="invoiceAddress.zip"
          label={t("admin.invoice_address.zip")}
          placeholder={t("admin.invoice_address.zip_helper")}
          violations={violations}
          value={zip}
          updateValue={setZip}
          required={true}/>
        <ElementInputWithLabel
          name="invoiceAddress.city"
          label={t("admin.invoice_address.city")}
          placeholder={t("admin.invoice_address.city_helper")}
          violations={violations}
          value={city}
          updateValue={setCity}
          required={true}/>
        <ElementInputWithLabel
          name="invoiceAddress.vat"
          label={t("admin.invoice_address.vat")}
          placeholder={t("admin.invoice_address.vat_helper")}
          violations={violations}
          value={vat}
          updateValue={setVat}
          required={true}/>
        <ElementInputWithLabel
          name="invoiceAddress.phone"
          label={t("admin.invoice_address.phone")}
          placeholder={t("admin.invoice_address.phone_helper")}
          violations={violations}
          value={phone}
          updateValue={setPhone}
          required={true}/>
      </Card>
      <Footer/>
    </PageForm>
  )
}
